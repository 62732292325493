import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CuponsService from "~services/shoppingService/cupon_api";



const initialState = {
  cupons: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedCupons: [],
  errorMessage: [],
}

export const fetchCupons = createAsyncThunk(
  "cupon/fetchCupons",
  async () => {
    const response = await CuponsService.fetchCupons();
    return response;
  }
);


export const searchCupons = createAsyncThunk(
  "cupon/searchCupons",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await CuponsService.fetchCupons(searchTerm, pageNumber, pageSize);
    return response;
  }
);

export const sortCuponsById = createAsyncThunk(
  "cupon/sortCuponsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await CuponsService.fetchCupons(order, pageNumber, pageSize);
    return response;
  }
);

export const createCupon = createAsyncThunk(
  "cupon/createCupon",
  async ({ data }) => {
    const response = await CuponsService.createCupon(data);
    return response;
  }
);


export const updateCupon = createAsyncThunk(
  "cupon/updateCupon",
  async ({ id, data }) => {
    const response = await CuponsService.updateCupon(id, data);
    return response;
  }
);

export const deleteCupon = createAsyncThunk(
  "cupon/deleteCupon",
  async ({ id }) => {
    await CuponsService.deleteCupon(id);
    return id; // Trả về cuponId sau khi đã xóa
  }
);

const cuponSlice = createSlice({
  name: "cupon",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedCupons = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.cupons = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCupons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cupons = action.payload;
      })
      .addCase(fetchCupons.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchCupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchCupons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cupons = action.payload;
      })
      .addCase(searchCupons.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortCuponsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortCuponsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cupons = action.payload;
      })
      .addCase(sortCuponsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createCupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCupon.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createCupon.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCupon.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateCupon.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateCupon.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteCupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCupon.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteCupon.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = cuponSlice;
export default reducer;

export const { clearSelectedRows } = cuponSlice.actions;
export const { setSelectedRowKeys } = cuponSlice.actions;
export const { setSelectedRows } = cuponSlice.actions;
