import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { encryptData } from '~hooks/auth/cryptoHelpers';

interface UserInfo {
  userId: number;
  userName: string;
  email: string;
  role: string;
  status: string;
}

interface AuthState {
  isLoading: boolean;
  isSignedIn: boolean;
  user: UserInfo | null;
  token: string;
  error: string | null;
  isRememberMe: boolean;
}

const initialState: AuthState = {
  isLoading: true,
  isSignedIn: false,
  user: null,
  token: "",
  error: null,
  isRememberMe: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<{ token: string }>) => {
      const encryptedToken = encryptData(action.payload);
      state.isSignedIn = true;
      state.token = encryptedToken;
      state.isLoading = false;
      state.error = null;
      // state.isRememberMe = !state.isRememberMe;
      state.isRememberMe ? localStorage.setItem('ConfigToken', encryptedToken) : sessionStorage.setItem('ConfigToken', encryptedToken);
    },

    loginFailure: (state, action: PayloadAction<string>) => {
      state.isSignedIn = false;
      state.user = null;
      state.isLoading = false;
      state.error = action.payload;
    },

    logoutSuccess: (state) => {
      state.isSignedIn = false;
      state.user = null;
      state.token = "";
      state.isLoading = false;
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('ConfigToken');
      localStorage.removeItem('ConfigUser');
      sessionStorage.removeItem('ConfigToken');
      sessionStorage.removeItem('ConfigUser');
      localStorage.removeItem('SanPhamDaThich');
      localStorage.removeItem('SanPhamDaXem');

      // localStorage.removeItem('cartItems');
      // localStorage.removeItem('totalAmount');
    },

    toggleRememberMe: (state, action) => {
      state.isRememberMe = action.payload;
    },

    setUserLoginInfo: (state, action: PayloadAction<UserInfo>) => {
      const encryptedUser = encryptData(action.payload);
      state.user = encryptedUser;
      // state.isRememberMe = !state.isRememberMe;
      state.isRememberMe ? localStorage.setItem('ConfigUser', encryptedUser) : sessionStorage.setItem('ConfigUser', encryptedUser);
    },
  }
});

export const { setUserLoginInfo, loginSuccess, loginFailure, logoutSuccess, toggleRememberMe } = authSlice.actions;

export default authSlice.reducer;
