/* eslint-disable react/jsx-pascal-case */
import React, { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import AdminLayout from '~layouts/adminLayout/index.tsx';
import SellerLayout from '~layouts/sellerLayout';
import CustomLayout from '~layouts/commonLayout/index.tsx';
import useAuth from '~hooks/auth/useAuth.tsx';
import Loading from '~components/common/other/loading/Loading';

//======================= Khai báo các đường dẫn của trang ======================//
const ROUTE_PATHS = {
  // Admin
  CEO: '/ceo', // Trang chủ CEO
  CEO_BLOG: 'blog-manager', // Trang chủ blog của CEO
  CEO_SHOPPING: 'shopping-manager', // Trang chủ shopping của CEO
  SELLER_MANAGER: 'seller-manager', // Trang chủ bán hàng của CEO
  ANALYS: 'analys', // Phân tích dữ liệu tổng quát
  PRODUCT_MANAGER: 'product-manager', // Quản lý sản phẩm
  USER_MANAGER: 'user-manager', // Quản lý người dùng
  ORDER_MANAGER: 'order-manager', // Quản lý đơn hàng
  ORDER_MANAGER_RUTGON: 'order-manager-rutgon', // Quản lý đơn hàng rút gọn
  CATEGORY_MANAGER: 'categories-manager', // Quản lý danh mục
  BRAND_MANAGER: 'brand-manager', // Quản lý thương hiệu
  INVENTORY_MANAGER: 'inventory-manager', // Quản lý tồn kho
  SHIPPING_MANAGER: 'shippingMethod-manager', // Quản lý vận chuyển
  PAYMENT_MANAGER: 'paymentMethod-manager', // Quản lý thanh toán
  CAMPAIGN_MANAGER: 'campaign-manager', // Quản lý sự kiện
  SUPPLIER_MANAGER: 'supplier-manager', // Quản lý nhà cung cấp
  CUPON_MANAGER: 'cupon-manager', // Quản lý khuyến mãi
  CHITIEU_MANAGER: 'chi-tieu-manager', // Quản lý chi tiêu
  SAPHETHANG: "sap-het-hang",
  OPTION: 'option',
  OPTION2: 'tenOption',
  OPTION3: 'option3',
  OPTION4: 'option4',
  IMAGE_UPLOAD: 'image-upload',


  //Tìm kiếm
  GLOBAL_SEARCH: 'search', // Trang tìm kiếm chính
  TEST: 'test',

  //Home
  HOME: 'home', // Trang chủ

  // Bán hàng trực tiếp
  SELLER: '/seller', // Trang chủ Seller


  //Blog
  BLOG: 'blog', // Trang chủ blog
  BLOG_MANAGER: 'blog-manager', // Quản lý bài viết
  BLOG_CATEGORY_MANAGER: 'blog-category-manager', // Quản lý chủ đề bài viết
  BLOGDETAIL: 'blogDetail', // Chi tiết bài viết
  BLOGCATEGORYPAGE: 'chu-de', // Trang chủ chủ đề - render các bài viết theo chủ đề
  BLOG_ABOUT: 'about', // Trang giới thiệu tổng thể trang web
  BLOG_CONTACT: 'contact', // Trang liên hệ


  //Shopping
  SHOPPING: 'shopping', // Trang chủ shopping
  CHECKOUT: 'checkout', // Trang thanh toán
  ORDER: 'order', // Trang đặt hàng
  QUANGCAO: 'quangcao', // Trang quảng cáo
  PRODUCT_DETAIL: 'productDetail', // Trang chi tiết sản phẩm
  ORDER_HISTORY: 'order_history', // Trang lịch sử đơn hàng
  CART: 'cart', // Trang giỏ hàng
  CAMPAIGN_PAGE: 'campaigns', // Trang danh mục sản phẩm
  COUPON_PAGE: 'cupons', // Trang danh mục sản phẩm


  //Mini Tools
  AMLICH: 'am-lich',
  TINHNGAY: 'tien_ich_nho', // Trang tiện ích nhỏ
  VNPAY_RESULT: 'vnpay-result', // Trang kết quả thanh toán VNPay
  DICH: 'dich',


  //User
  USER_PROFILE: '/userProfile', // Trang thông tin người dùng
  USER_GENERAL: '/userProfile/general', // Trang thông tin chung
  USER_CHANGE_PASSWORD: '/userProfile/change-password', // Trang thay đổi mật khẩu
  USER_CHANGE_EMAIL: '/userProfile/change-email', // Trang thay đổi email
  USER_ADDRESSLIST: '/userProfile/address-book', // Trang sổ địa chỉ
  CREATE_REVIEW: 'create-review', // Trang tạo đánh giá


  //Other
  LOGIN: 'auth/signIn', // Trang đăng nhập
  SIGN_UP: 'auth/signUp', // Trang đăng ký
  RESET_PASSWORD: 'resetPassword', // Trang đặt lại mật khẩu
  CHANGE_PASSWORD: 'changePassword', // Trang thay đổi mật khẩu
  LIKED_ITEM: 'liked_item', // Trang sản phẩm và bài viết đã thích
  LIKED_BLOG: 'liked_blog',   // Trang bài viết đã thích
  LIKED_PRODUCT: 'liked_product', // Trang sản phẩm đã thích
  BANKING_PAYMENT_RESULT: 'banking-payment-result', // Trang kết quả thanh toán ngân hàng
  CHAT: 'chat', // Trang chat
  SHOW_ALL_NOTIFICATION: 'showAllNotifycation', // Trang hiển thị tất cả thông báo
  ERROR_404: '404', // Trang lỗi 404
  ERROR_500: '500', // Trang lỗi 500

  RETURN_POLICE: 'return_police', // Trang bảo vệ thông tin cá nhân
  SECURITY_POLICE: 'security_police', // Trang bảo mật
  SHIPPING_POLICE: 'shipping_police', // Trang bảo vệ thông tin vận chuyển
  CANCEL_POLICE: 'cancel_police', // Trang bảo vệ thông tin hoàn hủy
  HOW_TO_PAY: 'how_to_pay', // Trang hướng dẫn thanh toán
};


//======================= Khai báo các component được load theo từng trang ======================//
// Quản lý các component được load theo từng trang
const LazyComponents = {
  //Common
  IndexPage: lazy(() => import("~pages/common/nostones-homePage/Nostones_HomePage")),
  // ChatBox: lazy(() => import("~pages/commonPage/chat/ChatBox.tsx")),
  ChatBox: lazy(() => import("~components/firebase/chat-app/chat_admin")),
  // Tìm kiếm
  GlobalSearch: lazy(() => import("~pages/common/search-page/GlobalSearch")),


  //Ceo
  CEO_HomePage: lazy(() => import('~pages/admin-page/CEO_HomePage/CEO_HomePage')),
  CEO_Blog_HomePage: lazy(() => import('~pages/admin-page/CEO_HomePage/CEO_Blog_HomePage')),
  CEO_Shopping_HomePage: lazy(() => import('~pages/admin-page/CEO_HomePage/CEO_Shopping_HomePage')),
  Seller_HomePage: lazy(() => import('~pages/admin-page/CEO_HomePage/Seller_HomePage')),
  AnalysPage: lazy(() => import('~pages/admin-page/CEO/Dashboard/index')),
  UserManager: lazy(() => import('~pages/admin-page/user_manager/index')),
  BrandManager: lazy(() => import('~pages/admin-page/brand_manager/index')),
  InventoryManager: lazy(() => import('~pages/admin-page/inventory_manager')),
  ShippingManager: lazy(() => import('~pages/admin-page/shipping_manager/index')),
  PaymentManager: lazy(() => import('~pages/admin-page/payment_manager/index')),
  CampaignManager: lazy(() => import('~pages/admin-page/campaign_manager/index')),
  CuponManager: lazy(() => import('~pages/admin-page/cupon_manager/index')),
  SupplierManager: lazy(() => import('~pages/admin-page/supplier_manager/index')),
  ProductManager: lazy(() => import('~pages/admin-page/product_manager')),
  OrderManager: lazy(() => import('~pages/admin-page/order_manager/index')),
  OrderManagerRutGon: lazy(() => import('~pages/admin-page/order_manager_rutgon/index')),
  CategoryManager: lazy(() => import('~pages/admin-page/product_category_manager/index')),
  ChiTieuManager: lazy(() => import('~pages/admin-page/chiTieu_manager/index')),
  SapHetHang: lazy(() => import('~pages/admin-page/productsLowStock/ProductsLowStock')),
  OptionPage: lazy(() => import('~pages/admin-page/other/optionPage/OptionPage')),
  // OptionPage2: lazy(() => import('~pages/commonPage/adminPage/optionPage2/OptionPage2')),
  OptionPage3: lazy(() => import('~pages/admin-page/other/optionPage3/OptionPage3')),
  OptionPage4: lazy(() => import('~pages/admin-page/other/optionPage4/OptionPage4')),
  // OptionPage4: lazy(() => import('~components/firebase/chat-app/chat')),
  // ImageUpload: lazy(() => import('~components/image/cloudinary/upload_link')),


  // Bán hàng trực tiếp
  PosScreen: lazy(() => import('~pages/shopping/BanHangTrucTiep/POSScreen')),



  //Blog
  BlogHomePage: lazy(() => import('~pages/blog/blog-homePage/blogHomePage')),
  BlogDetail: lazy(() => import('~pages/blog/blog-detail/BlogDetail')),
  BlogCategoryPage: lazy(() => import('~pages/blog/blogCategory-page/blogCategoryPreivewList')),
  BlogManager: lazy(() => import('~pages/blog/blog-manager/BlogManager')),
  BlogCategoryManager: lazy(() => import('~pages/blog/blogCategory-manager/BlogCategoryManager')),
  Blog_About: lazy(() => import('~layouts/component/About')),
  Blog_Contact: lazy(() => import('~layouts/component/Contact')),



  //Other
  LoginPage: lazy(() => import('~pages/common/auth-page/logIn-page/login')),
  SignUpPage: lazy(() => import('~pages/common/auth-page/signUp-page/sign_up')),
  Page404: lazy(() => import('~pages/common/error-page/Page404')),
  Page500: lazy(() => import('~pages/common/error-page/Page500')),
  ResetPassWord: lazy(() => import('~pages/common/auth-page/resetPassword/ResetPassword')),
  // ChangePassWord: lazy(() => import('~pages/common/auth-page/changePassword/changePassword')),
  ShowAllNotification: lazy(() => import('~layouts/component/NotificationsComponent')),
  BankingPaymentResult: lazy(() => import('~components/payment/chuyenkhoan/ChuyenKhoanResult')),


  // Test: lazy(() => import('~components/utils/ShippingServices/GHTK/Test')),



  //Footer
  ReturnPolice: lazy(() => import('~layouts/component/footer-about/ChinhSachDoiTra')),
  SecurityPolice: lazy(() => import('~layouts/component/footer-about/ChinhSachBaoMatTongHop')),
  ShippingPolice: lazy(() => import('~layouts/component/footer-about/ChinhSachGiaoVan')),
  CancelPolice: lazy(() => import('~layouts/component/footer-about/ChinhSachHoanHuy')),
  HowToPay: lazy(() => import('~layouts/component/footer-about/CachThanhToanBangVnPay')),



  //Shopping
  ShoppingPage: lazy(() => import('~pages/shopping/shopping-homePage/shopping_HomePage')),
  ProductDetail: lazy(() => import('~pages/shopping/product/ProductDetail/Product_Detail')),
  CartPage: lazy(() => import('~pages/shopping/cart-page/CartPage')),
  Checkout: lazy(() => import('~pages/shopping/checkout-page/Checkout')),
  CampaignPage: lazy(() => import('~pages/shopping/campaign-page/campaignPage')),
  CuponPage: lazy(() => import('~pages/shopping/cupon-page/home_cuponItem')),


  //User
  OrderHistory: lazy(() => import('~pages/user/order-history/OrderHistory')),
  LikedItem: lazy(() => import('~pages/user/liked-item/Liked_Item')),
  LikedBlog: lazy(() => import('~pages/user/liked-item/liked_Blog')),
  LikedProduct: lazy(() => import('~pages/user/liked-item/liked_Product')),
  CreateReview: lazy(() => import('~pages/user/order-history/components/ProductReview')),

  UserInfo: lazy(() => import('~pages/user/user-Info/MyProfile')),
  UserGeneral: lazy(() => import('~pages/user/user-Info/ProfileForm')),
  UserChangePassWord: lazy(() => import('~pages/user/user-Info/ChangePassword')),
  UserChangeEmail: lazy(() => import('~pages/user/user-Info/ChangeEmail')),
  UserAddressList: lazy(() => import('~pages/user/user-Info/AddressList')),


  //Mini Tools
  AmLich: lazy(() => import('~pages/common/mini-tool/LichAm')),
  TinhNgay: lazy(() => import('~pages/common/mini-tool/DateCalculator')),
  DoiSoTheYuCho: lazy(() => import('~components/firebase/chat-app/userList')),
  Dich: lazy(() => import('~pages/common/mini-tool/Translator')),
  VnPay: lazy(() => import('~components/payment/vn-QrPay/QRPay')),
  VnPay_Result: lazy(() => import('~components/payment/vn-QrPay/QRPayResult')),
};


//=================================== Quản lý đường dẫn của toàn bộ trang ===================================//
export default function Router() {
  //Kiểm tra xem người dùng đã đăng nhập chưa và có phải CEO không
  const { isLogIn, isCEO, isSeller } = useAuth();


  //Dùng useRoutes để quản lý các đường dẫn của trang
  const routes = useRoutes([
    //==================================== Các đường dẫn của CEO ====================================//
    {
      path: ROUTE_PATHS.CEO,
      element: isLogIn && isCEO ? (
        //Nếu đã đăng nhập và là CEO thì hiển thị layout của CEO
        <AdminLayout isLogIn={isLogIn} isCEO={isCEO}>
          <Suspense fallback={
            <div><Loading /></div>}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      ) : (
        //Nếu chưa đăng nhập hoặc không phải CEO thì chuyển hướng về trang đăng nhập
        <Navigate to={`/${ROUTE_PATHS.LOGIN}`} />),
      children: [
        // Trang chủ CEO
        // eslint-disable-next-line react/jsx-pascal-case
        { element: <LazyComponents.CEO_HomePage />, index: true },

        // Trang phân tích dữ liệu của CEO
        { path: ROUTE_PATHS.ANALYS, element: <LazyComponents.AnalysPage /> },

        // Trang quản lý bán hàng của CEO
        { path: ROUTE_PATHS.CEO_SHOPPING, element: <LazyComponents.CEO_Shopping_HomePage /> },

        // Trang quản lý blog của CEO
        { path: ROUTE_PATHS.CEO_BLOG, element: <LazyComponents.CEO_Blog_HomePage /> },

        // Quản lý người dùng của CEO
        { path: ROUTE_PATHS.USER_MANAGER, element: <LazyComponents.UserManager /> },

        // Quản lý chi tiêu của CEO
        { path: ROUTE_PATHS.CHITIEU_MANAGER, element: <LazyComponents.ChiTieuManager /> },

        // Quản lý sản phẩm sắp hết hàng
        { path: ROUTE_PATHS.SAPHETHANG, element: <LazyComponents.SapHetHang /> },

        {
          // Trang quản lý blog của CEO
          path: ROUTE_PATHS.CEO_BLOG,
          element:
            <Suspense fallback={
              <div><Loading /></div>}>
              <Outlet />
            </Suspense>
          , children: [
            // Trang chủ quản lý blog của CEO
            // eslint-disable-next-line react/jsx-pascal-case
            { element: <LazyComponents.CEO_Blog_HomePage />, index: true },

            // Trang quản lý bài viết
            { path: ROUTE_PATHS.BLOG_MANAGER, element: <LazyComponents.BlogManager /> },

            // Trang quản lý chủ đề bài viết
            { path: ROUTE_PATHS.BLOG_CATEGORY_MANAGER, element: <LazyComponents.BlogCategoryManager /> },
          ]
        },

        {
          // Trang quản lý shopping của CEO
          path: ROUTE_PATHS.CEO_SHOPPING,
          element:
            <Suspense fallback={
              <div><Loading /></div>}>
              <Outlet />
            </Suspense>
          , children: [
            // Trang chủ quản lý shopping của CEO
            // eslint-disable-next-line react/jsx-pascal-case
            { element: <LazyComponents.CEO_Shopping_HomePage />, index: true },

            // Trang quản lý sản phẩm
            { path: ROUTE_PATHS.PRODUCT_MANAGER, element: <LazyComponents.ProductManager /> },

            // Trang quản lý đơn hàng
            { path: ROUTE_PATHS.ORDER_MANAGER, element: <LazyComponents.OrderManager /> },

            // Trang quản lý đơn hàng rút gọn
            { path: ROUTE_PATHS.ORDER_MANAGER_RUTGON, element: <LazyComponents.OrderManagerRutGon /> },

            // Trang quản lý danh mục
            { path: ROUTE_PATHS.CATEGORY_MANAGER, element: <LazyComponents.CategoryManager /> },

            // Trang quản lý thương hiệu
            { path: ROUTE_PATHS.BRAND_MANAGER, element: <LazyComponents.BrandManager /> },

            // Trang quản lý tồn kho
            { path: ROUTE_PATHS.INVENTORY_MANAGER, element: <LazyComponents.InventoryManager /> },

            // Trang quản lý dịch vụ vận chuyển
            { path: ROUTE_PATHS.SHIPPING_MANAGER, element: <LazyComponents.ShippingManager /> },

            // Trang quản lý phương thức thanh toán
            { path: ROUTE_PATHS.PAYMENT_MANAGER, element: <LazyComponents.PaymentManager /> },

            // Trang quản lý chiến dịch quảng cáo
            { path: ROUTE_PATHS.CAMPAIGN_MANAGER, element: <LazyComponents.CampaignManager /> },

            // Trang quản lý mã giảm giá Cupon
            { path: ROUTE_PATHS.CUPON_MANAGER, element: <LazyComponents.CuponManager /> },

            // Trang quản lý nhà cung cấp
            { path: ROUTE_PATHS.SUPPLIER_MANAGER, element: <LazyComponents.SupplierManager /> },
          ]
        },

        // Trang chat
        { path: ROUTE_PATHS.CHAT, element: <LazyComponents.ChatBox /> },

        { path: ROUTE_PATHS.OPTION, element: <LazyComponents.OptionPage /> },
        // { path: ROUTE_PATHS.OPTION2, element: <LazyComponents.OptionPage2 /> },
        { path: ROUTE_PATHS.OPTION3, element: <LazyComponents.OptionPage3 /> },
        { path: ROUTE_PATHS.OPTION4, element: <LazyComponents.OptionPage4 /> },
        // { path: ROUTE_PATHS.IMAGE_UPLOAD, element: <LazyComponents.ImageUpload /> },
      ],
    },


    //==================================== Các đường dẫn của Seller ====================================//
    {
      path: ROUTE_PATHS.SELLER,
      element: isLogIn && (isSeller || isCEO) ? (
        // Nếu đã đăng nhập và là Seller hoặc CEO thì hiển thị layout của Seller
        <SellerLayout isLogIn={isLogIn} isSeller={isSeller}>
          <Suspense fallback={<div><Loading /></div>}>
            <Outlet />
          </Suspense>
        </SellerLayout>
      ) : (
        // Nếu chưa đăng nhập hoặc không phải Seller hoặc CEO thì chuyển hướng về trang đăng nhập
        <Navigate to={`/${ROUTE_PATHS.LOGIN}`} />
      ),
      children: [
        // Trang chủ Seller
        { element: <LazyComponents.PosScreen />, index: true },

        // Trang quản lý bán hàng
        { path: ROUTE_PATHS.SELLER_MANAGER, element: <LazyComponents.Seller_HomePage /> },

        // Trang chat
        { path: ROUTE_PATHS.CHAT, element: <LazyComponents.ChatBox /> },

        // Trang quản lý đơn hàng rút gọn
        { path: ROUTE_PATHS.ORDER_MANAGER_RUTGON, element: <LazyComponents.OrderManagerRutGon /> },
      ],
    },





    //==================================== Các đường dẫn chung ====================================//
    // CÁC ĐƯỜNG CẦN ĐĂNG NHẬP
    {
      path: '/',
      element: isLogIn ? (
        // Nếu đã đăng nhập thì hiển thị layout chung
        <CustomLayout isLogIn={isLogIn}>
          <Suspense fallback={
            <div><Loading /></div>}>
            <Outlet />
          </Suspense>
        </CustomLayout>
      ) : (
        //Nếu chưa đăng nhập hoặc không phải CEO thì chuyển hướng về trang đăng nhập
        <Navigate to={ROUTE_PATHS.LOGIN} />),

      children: [
        // Trang sản phẩm và bài viết đã thích
        { path: ROUTE_PATHS.LIKED_ITEM, element: <LazyComponents.LikedItem /> },

        // Trang bài viết đã thích
        { path: ROUTE_PATHS.LIKED_BLOG, element: <LazyComponents.LikedBlog /> },

        // Trang sản phẩm đã thích
        { path: ROUTE_PATHS.LIKED_PRODUCT, element: <LazyComponents.LikedProduct /> },

        // Trang lịch sử đơn hàng
        { path: ROUTE_PATHS.ORDER_HISTORY, element: <LazyComponents.OrderHistory /> },

        // Trang hiển thị tất cả thông báo
        //@ts-ignore
        { path: ROUTE_PATHS.SHOW_ALL_NOTIFICATION, element: <LazyComponents.ShowAllNotification /> },


        // Trang thông tin info người dùng
        {
          path: ROUTE_PATHS.USER_PROFILE, element: <LazyComponents.UserInfo />, children: [
            { element: <LazyComponents.UserInfo /> },

            // Trang thông tin chung
            { path: ROUTE_PATHS.USER_GENERAL, element: <LazyComponents.UserGeneral /> },

            // Trang thay đổi mật khẩu
            { path: ROUTE_PATHS.USER_CHANGE_PASSWORD, element: <LazyComponents.UserChangePassWord /> },

            // Trang thay đổi email
            { path: ROUTE_PATHS.USER_CHANGE_EMAIL, element: <LazyComponents.UserChangeEmail /> },

            // Trang sổ địa chỉ người dùng
            { path: ROUTE_PATHS.USER_ADDRESSLIST, element: <LazyComponents.UserAddressList /> },

          ]
        },

        // Trang trả kết quả thanh toán VNPay
        // eslint-disable-next-line react/jsx-pascal-case
        { path: ROUTE_PATHS.VNPAY_RESULT, element: <LazyComponents.VnPay_Result /> },

        // Trang kết quả thanh toán ngân hàng
        // eslint-disable-next-line react/jsx-pascal-case
        { path: ROUTE_PATHS.BANKING_PAYMENT_RESULT, element: <LazyComponents.BankingPaymentResult /> },
      ]
    },



    // CÁC ĐƯỜNG DẪN KHÔNG CẦN ĐĂNG NHẬP
    {
      path: '/',
      element:
        <CustomLayout isLogIn={isLogIn}>
          <Suspense fallback={
            <div><Loading /></div>}>
            <Outlet />
          </Suspense>
        </CustomLayout>,
      children: [
        //=========================== Các đường dẫn chung không cần đăng nhập =========================//

        // Trang chủ NoStones Home Page
        // { element: <LazyComponents.IndexPage />, index: true },
        { element: <LazyComponents.ShoppingPage />, index: true },

        // Trang tìm kiếm chính
        { path: ROUTE_PATHS.GLOBAL_SEARCH, element: <LazyComponents.GlobalSearch /> },

        // Trang đăng ký
        { path: ROUTE_PATHS.LOGIN, element: <LazyComponents.LoginPage /> },

        // Trang đăng nhập
        { path: ROUTE_PATHS.SIGN_UP, element: <LazyComponents.SignUpPage /> },

        // Trang đặt lại mật khẩu
        { path: ROUTE_PATHS.RESET_PASSWORD, element: <LazyComponents.ResetPassWord /> },
        // { path: ROUTE_PATHS.CHANGE_PASSWORD, element: <LazyComponents.ChangePassWord open={true} handleClose={() => {}} /> },

        // Trang viết đánh giá
        //@ts-ignore
        { path: ROUTE_PATHS.CREATE_REVIEW, element: <LazyComponents.CreateReview /> },


        //Footer
        // Trang thông tin cửa hàng
        // { path: ROUTE_PATHS.STORE_INFO, element: <LazyComponents.StoreInfo /> },

        // Trang chính sách bảo mật
        { path: ROUTE_PATHS.SECURITY_POLICE, element: <LazyComponents.SecurityPolice /> },

        // Trang chính sách vận chuyển
        { path: ROUTE_PATHS.SHIPPING_POLICE, element: <LazyComponents.ShippingPolice /> },

        // Trang chính sách hoàn hủy
        { path: ROUTE_PATHS.CANCEL_POLICE, element: <LazyComponents.CancelPolice /> },

        // Trang chính sách đổi trả
        { path: ROUTE_PATHS.RETURN_POLICE, element: <LazyComponents.ReturnPolice /> },

        // Trang hướng dẫn thanh toán
        { path: ROUTE_PATHS.HOW_TO_PAY, element: <LazyComponents.HowToPay /> },

        // Trang giới thiệu tổng thể trang web
        // eslint-disable-next-line react/jsx-pascal-case
        { path: `${ROUTE_PATHS.BLOG_ABOUT}`, element: <LazyComponents.Blog_About /> },

        // Trang liên hệ
        // eslint-disable-next-line react/jsx-pascal-case
        { path: `${ROUTE_PATHS.BLOG_CONTACT}`, element: <LazyComponents.Blog_Contact /> },




        // Blog - Khách có thể xem bài viết mà không cần đăng nhập
        {
          path: ROUTE_PATHS.BLOG,
          children: [
            // Trang chủ blog
            { element: <LazyComponents.BlogHomePage />, index: true },

            // Trang xem các bài viết theo cùng một chủ đề
            //@ts-ignore
            { path: `${ROUTE_PATHS.BLOGCATEGORYPAGE}/:slug/:blogCategoryId`, element: <LazyComponents.BlogCategoryPage /> },

            // Trang chi tiết bài viết
            //@ts-ignore
            { path: `${ROUTE_PATHS.BLOGDETAIL}/:slug/:blogId`, element: <LazyComponents.BlogDetail /> },

          ]
        },





        //Shopping - Khách có thể xem sản phẩm mà không cần đăng nhập
        {
          path: ROUTE_PATHS.SHOPPING,
          children: [
            // Trang chủ shopping
            { element: <LazyComponents.ShoppingPage />, index: true },

            // Trang thanh toán
            { path: ROUTE_PATHS.CHECKOUT, element: <LazyComponents.Checkout /> },

            // { path: `${ROUTE_PATHS.ORDER}/:id`, element: <LazyComponents.Quang_Cao /> },

            // Trang chi tiết sản phẩm
            //@ts-ignore
            { path: `${ROUTE_PATHS.PRODUCT_DETAIL}/:slug/:id`, element: <LazyComponents.ProductDetail /> },

            // Trang giỏ hàng
            //@ts-ignore
            { path: ROUTE_PATHS.CART, element: <LazyComponents.CartPage /> },

            // Trang các chiến dịch quảng cáo
            { path: `${ROUTE_PATHS.CAMPAIGN_PAGE}/:id`, element: <LazyComponents.CampaignPage /> },

            // Trang các mã khuyến mãi
            { path: `${ROUTE_PATHS.COUPON_PAGE}`, element: <LazyComponents.CuponPage /> },

          ]
        },

        {
          // Home - Trang chủ
          path: ROUTE_PATHS.HOME,
          element: <LazyComponents.IndexPage />
        },


        //Mini Tools - Các công cụ nhỏ hữu ích
        // Trang tính ngày
        { path: ROUTE_PATHS.TINHNGAY, element: <LazyComponents.TinhNgay /> },

        // Âm lịch
        { path: `${ROUTE_PATHS.AMLICH}`, element: <LazyComponents.AmLich /> },
      ]
    },


    // Trang lỗi 404 - Không tìm thấy trang
    { path: ROUTE_PATHS.ERROR_404, element: <LazyComponents.Page404 /> },

    // Trang lỗi 500 - Lỗi server
    { path: ROUTE_PATHS.ERROR_500, element: <LazyComponents.Page500 /> },

    // Trả tất cả các đường dẫn không hợp lệ về trang lỗi 404
    { path: "*", element: <LazyComponents.Page404 /> },
  ]);

  return routes;
}