import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";


// Tìm kiếm toàn cục
const searchAll = async (keyword, page, size, productFilter, blogFilter) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/search`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                keyword,
                page,
                size,
                productFilter,
                blogFilter
            }
        });
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('Có lỗi xảy ra khi tìm kiếm dữ liệu!');
    }
};


// Gợi ý tìm kiếm
const searchSuggestion = async (keyword) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/search/suggestions`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                keyword
            }
        });
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('Có lỗi xảy ra khi tìm kiếm dữ liệu!');
    }
}

const SearchService = {
    searchAll,
    searchSuggestion
}
export default SearchService;