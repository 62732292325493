import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCartPopup } from '~redux/slices/shopping/cartSlice';
import { RootState } from '~redux/store';
import CartPreview from '~components/shopping/cartPreview/CartPreview';
import IconButton from '@mui/material/IconButton';
import CartIcon from '~components/image/main_icon/carticon1.png';

interface HeaderProps {
    cartBounce: boolean;
}

interface CartItemType {
    donGia: number;
    soLuong: number;
    isMuaSau: boolean;
}

const CartPopover: React.FC<HeaderProps> = (props) => {
    const cartItems = useSelector((state: RootState) => state.cart.items as CartItemType[]);
    const [cartQuantity, setCartQuantity] = useState(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        const quantity = cartItems.filter(item => item.isMuaSau === false).length;
        setCartQuantity(quantity);
    }, [cartItems]);

    const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
    const dispatch = useDispatch();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(toggleCartPopup());
    };

    const handleClose = () => {
        setAnchorEl(null);
        dispatch(toggleCartPopup());
    };

    return (
        <div>
            <IconButton
                color={isCartOpen ? 'primary' : 'default'}
                onClick={handleOpen}
            >
                <Badge badgeContent={cartQuantity} color="error" style={{ marginRight: "10px" }}>
                    <img src={CartIcon} alt="Cart" style={{ width: 26, height: 26 }} />
                </Badge>
            </IconButton>

            <Popover
                open={isCartOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {isCartOpen && <CartPreview closeCart={handleClose} />}
            </Popover>
        </div>
    );
}

export default CartPopover;