import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { NAV, FOOTER } from '~layouts/component/config-layout';
import { bgBlur } from '~components/theme/css.tsx';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FacebookLogo from '~components/image/share-icon/Facebook_Icon';
import InstargramLogo from '~components/image/share-icon/Instargram_Icon';
import YoutubeLogo from '~components/image/share-icon/Youtube_Icon';
import ZaloIcon from '~components/image/share-icon/Zalo_Icon';
import TiktokIcon from '~components/image/share-icon/Titok_Icon';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';

import HopTac from '~layouts/component/footer-about/hoptac.png';

const currentYear = new Date().getFullYear();

const Footer = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    useEffect(() => {
        const language = localStorage.getItem('language');
        if (language) {
            i18n.changeLanguage(language);
        } else {
            i18n.changeLanguage('vn');
        }
    }, [i18n]);

    //@ts-ignore
    const lgUp = useResponsive('up', 'lg');

    return (
        <Box
            //@ts-ignore
            sx={{
                boxShadow: 'none',
                height: FOOTER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    marginLeft: "auto",
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: FOOTER.H_DESKTOP,
                }),
            }}
        >
            <div style={{
                background: "#f6fafb",
                padding: "30px 0",
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                borderTop: '1px solid #DDDDDD'
            }}>

                <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row" }}>

                    <Grid size={{ md: 3.5 }} style={{ marginLeft: "5px" }} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: '500', color: 'black' }}>
                            {t('contactInfo')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'black', display: 'inline-flex', alignItems: 'center', '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}>
                            <LocationOnIcon sx={{ mr: 0.5, color: "#212b36", height: "15px", width: "15px" }} />
                            <span onClick={() => window.open("https://www.google.co.jp/maps/place/Nh%E1%BA%ADt+B%E1%BA%A3n/@31.6335906,115.6959911,5441888m/data=!3m2!1e3!4b1!4m6!3m5!1s0x34674e0fd77f192f:0xf54275d47c665244!8m2!3d36.204824!4d138.252924!16zL20vMDNfM2Q?entry=ttu", "_blank", "noopener noreferrer")} style={{ color: '#212b36', textDecoration: 'none' }}>{t('address')}</span>
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'black', display: 'inline-flex', alignItems: 'center' }}>
                            <PhoneIcon sx={{ mr: 0.5, color: "#212b36", height: "15px", width: "15px" }} /> {t('phone')}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'black', display: 'inline-flex', alignItems: 'center', '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}>
                            <EmailIcon sx={{ mr: 0.5, color: "#212b36", height: "15px", width: "15px" }} />
                            <span onClick={() => window.location.href = "mailto:nostones.vn@gmail.com"} style={{ color: '#212b36', textDecoration: 'none' }}>{t('email')}</span>
                        </Typography>

                    </Grid>

                    <Grid size={{ xs: 5.7, md: 2.8 }} sx={{ marginLeft: { xs: "10px", md: "5px" } }}>
                        <Typography variant="h6" gutterBottom
                            onClick={() => navigate('/about')}
                            sx={{
                                fontWeight: '500',
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.01)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                        >
                            {t('connectWithUs')}
                        </Typography>

                        <Box sx={{ display: 'flex', py: 1, marginLeft: { xs: "0", md: "13px" } }}>
                            <FacebookLogo url="https://www.facebook.com/nostones.vn" />
                            <InstargramLogo url="https://www.instagram.com/nostones.vn/" />
                            <YoutubeLogo url="https://www.youtube.com/@nostones_vn" />
                            <ZaloIcon url="https://zalo.me/84971520865" />
                            <TiktokIcon url="https://www.tiktok.com/@nostones.vn" />
                        </Box>

                        <Typography variant="body2" textAlign="left" sx={{ fontWeight: '500', color: 'black', marginLeft: { xs: "18px", md: "35px" } }} onClick={() => navigate('/about')}>
                            &copy; {currentYear} <span>Nostones</span>
                        </Typography>
                    </Grid>

                    <Grid size={{ xs: 5.5, md: 2.8 }} sx={{ marginLeft: { xs: "5px", md: "5px" } }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: '500', color: 'black' }}>{t('privacyPolicy')}</Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/security_police')}>
                            {t('privacyPolicy')}
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/shipping_police')}>
                            {t('shippingPolicy')}
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/cancel_police')}>
                            {t('cancelPolicy')}
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/return_police')}>
                            {t('returnPolicy')}
                        </Typography>
                    </Grid>

                    <Grid size={{ xs: 6 }} style={{ marginLeft: "5px" }} sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: '500', color: 'black' }}>
                            {t('contactInfo')}
                        </Typography>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <EmailIcon fontSize='small' style={{ height: "12px", color: 'black' }} />
                            <Typography variant="body2" sx={{ color: 'black' }}>
                                {!lgUp ?
                                    t('email').replace("Email:", "").trim() :
                                    t('email')
                                }
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <PhoneIcon fontSize='small' style={{ height: "12px", color: 'black' }} />
                            <Typography variant="body2" sx={{ color: 'black' }}>
                                {t('phone')}
                            </Typography>
                        </div>

                        <Typography variant="body2" sx={{ color: 'black' }}>
                            <LocationOnIcon fontSize='small' style={{ height: "12px", color: 'black' }} />
                            {!lgUp ?
                                t('address').replace("Địa chỉ:", "").trim() :
                                t('address')
                            }
                        </Typography>


                    </Grid>

                    <Grid size={{ xs: 5.5, md: 2.5 }} sx={{ marginLeft: "0px" }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: '500', color: 'black' }}>{t('cooperationContact')}</Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/contact')}>
                            {t('cooperationContact')}
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    color: '#57c8cc',
                                    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }
                            }}
                            onClick={() => navigate('/how_to_pay')}>
                            {t('paymentGuide')}
                        </Typography>
                        <img
                            height="52px"
                            width="130px"
                            src={HopTac} alt="Nostones contact" />
                    </Grid>
                </Grid>
            </div>

            {!lgUp && (
                <Header>
                    <img
                        src={`${process.env.PUBLIC_URL}/logo21.png`}
                        alt="Nostones Logo"
                    />
                </Header>
            )}
        </Box>
    );
}

export default Footer;

const Header = styled('header')(({ theme }) => ({
    backgroundColor: '#56c9cd',
    color: 'white',
    padding: '1em 0',
    textAlign: 'center',
    img: {
        height: '50px',
        verticalAlign: 'middle',
    },
    h1: {
        display: 'inline-block',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '2.5em',
        margin: 0,
    },
    p: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '1em',
        margin: 0,
    },
}));
