import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chiTieusService from "~services/paymentService/chiTieu_api";



const initialState = {
  chiTieus: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedChiTieus: [],
  errorMessage: [],
}
export const fetchChiTieus = createAsyncThunk(
  "chiTieu/fetchChiTieus",
  async () => {
    const response = await chiTieusService.fetchChiTieus();
    return response;
  }
);


export const createChiTieu = createAsyncThunk(
  "chiTieu/createChiTieu",
  async ({ data }) => {
    const response = await chiTieusService.createChiTieu(data);
    return response;
  }
);


export const updateChiTieu = createAsyncThunk(
  "chiTieu/updateChiTieu",
  async ({ id, data }) => {
    const response = await chiTieusService.updateChiTieu(id, data);
    return response;
  }
);

export const deleteChiTieu = createAsyncThunk(
  "chiTieu/deleteChiTieu",
  async ({ id }) => {
    await chiTieusService.deleteChiTieu(id);
    return id; // Trả về chiTieuId sau khi đã xóa
  }
);

const chiTieuSlice = createSlice({
  name: "chiTieu",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedChiTieus = action.payload;
    },
    selectedChiTieus: (state, action) => {
      state.selectedChiTieus = action.payload;
    },

    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.chiTieus = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChiTieus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchChiTieus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.chiTieus = action.payload;
      })
      .addCase(fetchChiTieus.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(createChiTieu.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createChiTieu.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createChiTieu.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateChiTieu.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateChiTieu.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateChiTieu.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteChiTieu.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteChiTieu.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteChiTieu.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = chiTieuSlice;
export default reducer;

export const { clearSelectedRows } = chiTieuSlice.actions;
export const { setSelectedRowKeys } = chiTieuSlice.actions;
export const { setSelectedRows } = chiTieuSlice.actions;
export const { selectedChiTieus } = chiTieuSlice.actions;
