import React, { useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Iconify from '~components/image/iconify/iconify.tsx';
import IconButton from '@mui/material/IconButton';
import { fetchCategories, selectedCategories } from '~redux/slices/shopping/categorySlice';
import { fetchBrands } from '~redux/slices/shopping/brandSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~redux/store';
import { isFilter } from '~redux/slices/common/commonSlice';

// @ts-ignore
export default function ProductCategoryList({ onIconClick }) {
    //@ts-ignore
    const Categories = useSelector((state: RootState) => state.category.categories.content) || [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategories() as any);
        dispatch(fetchBrands() as any);
    }, [dispatch]);

    const handleClick = async (category: any) => {
        await dispatch(isFilter(true));
        //@ts-ignore
        dispatch(selectedCategories(category.tenLoaiSanPham) as any);
        onIconClick();
    };

    return (
        <>
            <Helmet>
                {Categories.map((category: any) => (
                    <React.Fragment key={category.maLoaiSanPham}>
                        <title> {`${category.tenLoaiSanPham ? category.tenLoaiSanPham : "Danh mục sản phẩm"} - Nostones`} </title>
                    </React.Fragment>
                ))}
            </Helmet>

            <List>
                <Typography variant="h6" component="div" gutterBottom style={{ marginLeft: '10px', fontWeight: "100", marginTop: "5px", color: "#637381" }}>
                    Danh mục sản phẩm
                </Typography>
                {Categories.map((category: any) => (
                    <ListItemButton key={category.maLoaiSanPham} onClick={() => handleClick(category)}>
                        <ListItemIcon>
                            <IconButton>
                                <Iconify
                                    //@ts-ignore
                                    icon={category.icon} />
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText primary={category.tenLoaiSanPham} style={{ color: "#637381" }} />
                    </ListItemButton>
                ))}
            </List>
        </>
    );
};