import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const url = "/ChiTietPhieuNhapKho";

const fetchDetail_Inventorys = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`${url}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchDetail_Inventorys = async (maPhieuNhapKho) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`${url}/${maPhieuNhapKho}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
    });
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Detail_Inventorys');
    }
};

const sortDetail_InventorysById = async (order, pageNumber, pageSize) => {
    try {
        const sortOption = order === 'asc' ? 'maChiTietPhieuNhapKho,asc' : 'maChiTietPhieuNhapKho,desc';
        const response = await api.get(`${url}?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`,);
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Detail_Inventorys by id');
    }
};


const createDetail_Inventory = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post(`${url}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Detail_Inventorys failed');
    }
};


const updateDetail_Inventory = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`${url}/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Detail_Inventorys failed');
    }
};

const deleteDetail_Inventory = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`${url}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Detail_Inventorys deleted successfully';
    } catch (error) {
        throw new Error('Deleting Detail_Inventorys failed');
    }
};

const Detail_InventorysService = {
    fetchDetail_Inventorys,
    searchDetail_Inventorys,
    sortDetail_InventorysById,
    createDetail_Inventory,
    updateDetail_Inventory,
    deleteDetail_Inventory
}
export default Detail_InventorysService;