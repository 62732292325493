import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CampaignsService from "~services/shoppingService/campaign_api";
// import productAPI from '~services/shoppingService/product_api';
// import categoryAPI from '~services/shoppingService/product_category';
// import brandAPI from '~services/shoppingService/brand_api';


const initialState = {
  promotions: [],
  campaigns: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedCampaigns: [],
  errorMessage: [],
}

export const fetchCampaigns = createAsyncThunk(
  "campaign/fetchCampaigns",
  async () => {
    const response = await CampaignsService.fetchCampaigns();
    // console.log("CHIEN" + response);
    return response;
  }
);


export const searchCampaigns = createAsyncThunk(
  "campaign/searchCampaigns",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await CampaignsService.searchCampaigns(searchTerm, pageNumber, pageSize);
    // console.log("DUNG" + JSON.stringify(response));
    return response;
  }
);




// // Config sanPhams cho dữ liệu trả về từ API
// export const fetchCampaigns = createAsyncThunk(
//   "campaign/fetchCampaigns",
//   async (searchQuery) => {
//     try {
//       const response = await CampaignsService.fetchCampaigns();

//       // Collect all sanPhams arrays from the response
//       const allSanPhams = response.flatMap(event => event.sanPhams || []);

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const configResponse = await Promise.all(
//         allSanPhams.map(id => productAPI.getProductById(id))
//       );

//       const filteredResponse = configResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchQuery.toLowerCase()) && spkm.giamGia
//         )
//         .map(spkm => ({
//           maSanPham: spkm.maSanPham,
//           tenSanPham: spkm.tenSanPham,
//           giamGia: spkm.giamGia
//         }));

//       // Ensure unique filtered product details
//       const uniqueFilteredResponse = Array.from(new Set(filteredResponse.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredResponse.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams in each event with the filtered product details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredResponse.filter(product => event.sanPhams.includes(product?.maSanPham))
//       }));

//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in fetchCampaigns:", error);
//       throw error;
//     }
//   }
// );



// // Config sanPhams cho dữ liệu trả về từ API
// export const searchCampaigns = createAsyncThunk(
//   "campaign/searchCampaigns",
//   async ({ searchTerm, pageNumber, pageSize }) => {
//     try {
//       const response = await CampaignsService.searchCampaigns(searchTerm, pageNumber, pageSize);
//       // console.log("DUNG" + JSON.stringify(response));

//       // Collect all sanPhams arrays from the response
//       const allSanPhams = response.flatMap(event => event.sanPhams || []);

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const configResponse = await Promise.all(
//         allSanPhams.map(id => productAPI.getProductById(id))
//       );
//       // console.log("CONFIG RESPONSE" + JSON.stringify(configResponse));

//       const filteredResponse = configResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchTerm.toLowerCase()) && spkm.qrCode
//         )
//         .map(spkm => ({
//           qrCode: spkm.qrCode,
//           tenSanPham: spkm.tenSanPham,
//           maSanPham: spkm.maSanPham,
//           giamGia: spkm.giamGia
//         }));

//       // Ensure unique filtered product details
//       const uniqueFilteredResponse = Array.from(new Set(filteredResponse.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredResponse.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams in each event with the filtered product details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredResponse.filter(product => event.sanPhams.includes(product?.maSanPham))
//       }));

//       // console.log("TOAN" + JSON.stringify(modifiedResponse));
//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in searchCampaigns:", error);
//       throw error;
//     }
//   }
// );




// // Config sanPhams cho dữ liệu trả về từ API
// export const fetchCampaigns = createAsyncThunk(
//   "campaign/fetchCampaigns",
//   async (searchQuery) => {
//     try {
//       const response = await CampaignsService.fetchCampaigns();

//       // Collect all sanPhams, loaiSanPhams, and thuongHieus arrays from the response
//       const allSanPhams = response.flatMap(event => event.sanPhams || []);
//       const allLoaiSanPhams = response.flatMap(event => event.loaiSanPhams || []);
//       const allThuongHieus = response.flatMap(event => event.thuongHieus || []);

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const productResponse = await Promise.all(
//         allSanPhams.map(id => productAPI.getProductById(id))
//       );

//       const categoryResponse = await Promise.all(
//         allLoaiSanPhams.map(id => categoryAPI.fetchCategoryById(id))
//       );

//       const brandResponse = await Promise.all(
//         allThuongHieus.map(id => brandAPI.fetchBrandsById(id))
//       );

//       const filteredProducts = productResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchQuery.toLowerCase()) && spkm.giamGia
//         )
//         .map(spkm => ({
//           maSanPham: spkm.maSanPham,
//           tenSanPham: spkm.tenSanPham,
//           giamGia: spkm.giamGia
//         }));

//       const filteredCategories = categoryResponse.map(category => ({
//         maLoaiSanPham: category?.maLoaiSanPham,
//         tenLoaiSanPham: category?.tenLoaiSanPham
//       }));

//       const filteredBrands = brandResponse.map(brand => ({
//         maThuongHieu: brand?.maThuongHieu,
//         tenThuongHieu: brand?.tenThuongHieu
//       }));

//       // Ensure unique filtered product, category, and brand details
//       const uniqueFilteredProducts = Array.from(new Set(filteredProducts.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredProducts.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams, loaiSanPhams, and thuongHieus in each event with the filtered details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredProducts.filter(product => event.sanPhams.includes(product?.maSanPham)),
//         loaiSanPhams: filteredCategories,
//         thuongHieus: filteredBrands
//       }));

//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in fetchCampaigns:", error);
//       throw error;
//     }
//   }
// );



// // Config sanPhams cho dữ liệu trả về từ API
// export const searchCampaigns = createAsyncThunk(
//   "campaign/searchCampaigns",
//   async ({ searchTerm, pageNumber, pageSize }) => {
//     try {
//       const response = await CampaignsService.searchCampaigns(searchTerm, pageNumber, pageSize);

//       // Collect all sanPhams, loaiSanPhams, and thuongHieus arrays from the response
//       const allSanPhams = response.flatMap(event => event.sanPhams || []);
//       const allLoaiSanPhams = response.flatMap(event => event.loaiSanPhams || []);
//       const allThuongHieus = response.flatMap(event => event.thuongHieus || []);

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const productResponse = await Promise.all(
//         allSanPhams.map(id => productAPI.getProductById(id))
//       );

//       const categoryResponse = await Promise.all(
//         allLoaiSanPhams.map(id => categoryAPI.fetchCategoryById(id))
//       );

//       const brandResponse = await Promise.all(
//         allThuongHieus.map(id => brandAPI.fetchBrandsById(id))
//       );

//       const filteredProducts = productResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchTerm.toLowerCase()) && spkm.qrCode
//         )
//         .map(spkm => ({
//           qrCode: spkm.qrCode,
//           tenSanPham: spkm.tenSanPham,
//           maSanPham: spkm.maSanPham,
//           giamGia: spkm.giamGia
//         }));

//       const filteredCategories = categoryResponse.map(category => ({
//         maLoaiSanPham: category?.maLoaiSanPham,
//         tenLoaiSanPham: category?.tenLoaiSanPham
//       }));

//       const filteredBrands = brandResponse.map(brand => ({
//         maThuongHieu: brand?.maThuongHieu,
//         tenThuongHieu: brand?.tenThuongHieu
//       }));

//       // Ensure unique filtered product, category, and brand details
//       const uniqueFilteredProducts = Array.from(new Set(filteredProducts.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredProducts.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams, loaiSanPhams, and thuongHieus in each event with the filtered details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredProducts.filter(product => event.sanPhams.includes(product?.maSanPham)),
//         loaiSanPhams: filteredCategories,
//         thuongHieus: filteredBrands
//       }));

//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in searchCampaigns:", error);
//       throw error;
//     }
//   }
// );


// // Config sanPhams cho dữ liệu trả về từ API
// export const fetchCampaigns = createAsyncThunk(
//   "campaign/fetchCampaigns",
//   async (searchQuery) => {
//     try {
//       const response = await CampaignsService.fetchCampaigns();

//       // Collect all unique sanPhams, loaiSanPhams, and thuongHieus IDs from the response
//       const allSanPhams = [...new Set(response.flatMap(event => event.sanPhams || []))];
//       const allLoaiSanPhams = [...new Set(response.flatMap(event => event.loaiSanPhams || []))];
//       const allThuongHieus = [...new Set(response.flatMap(event => event.thuongHieus || []))];

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const [productResponse, categoryResponse, brandResponse] = await Promise.all([
//         Promise.all(allSanPhams.map(id => productAPI.getProductById(id))),
//         Promise.all(allLoaiSanPhams.map(id => categoryAPI.fetchCategoryById(id))),
//         Promise.all(allThuongHieus.map(id => brandAPI.fetchBrandsById(id)))
//       ]);

//       const filteredProducts = productResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchQuery.toLowerCase()) && spkm.giamGia
//         )
//         .map(spkm => ({
//           maSanPham: spkm.maSanPham,
//           tenSanPham: spkm.tenSanPham,
//           giamGia: spkm.giamGia
//         }));

//       const filteredCategories = categoryResponse.map(category => ({
//         maLoaiSanPham: category?.maLoaiSanPham,
//         tenLoaiSanPham: category?.tenLoaiSanPham
//       }));

//       const filteredBrands = brandResponse.map(brand => ({
//         maThuongHieu: brand?.maThuongHieu,
//         tenThuongHieu: brand?.tenThuongHieu
//       }));

//       // Ensure unique filtered product, category, and brand details
//       const uniqueFilteredProducts = Array.from(new Set(filteredProducts.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredProducts.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams, loaiSanPhams, and thuongHieus in each event with the filtered details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredProducts.filter(product => event.sanPhams.includes(product?.maSanPham)),
//         loaiSanPhams: filteredCategories,
//         thuongHieus: filteredBrands
//       }));

//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in fetchCampaigns:", error);
//       throw error;
//     }
//   }
// );



// // Config sanPhams cho dữ liệu trả về từ API
// export const searchCampaigns = createAsyncThunk(
//   "campaign/searchCampaigns",
//   async ({ searchTerm, pageNumber, pageSize }) => {
//     try {
//       const response = await CampaignsService.searchCampaigns(searchTerm, pageNumber, pageSize);

//       // Collect all unique sanPhams, loaiSanPhams, and thuongHieus IDs from the response
//       const allSanPhams = [...new Set(response.flatMap(event => event.sanPhams || []))];
//       const allLoaiSanPhams = [...new Set(response.flatMap(event => event.loaiSanPhams || []))];
//       const allThuongHieus = [...new Set(response.flatMap(event => event.thuongHieus || []))];

//       if (!allSanPhams.length) {
//         throw new Error("Invalid response: no sanPhams found");
//       }

//       const [productResponse, categoryResponse, brandResponse] = await Promise.all([
//         Promise.all(allSanPhams.map(id => productAPI.getProductById(id))),
//         Promise.all(allLoaiSanPhams.map(id => categoryAPI.fetchCategoryById(id))),
//         Promise.all(allThuongHieus.map(id => brandAPI.fetchBrandsById(id)))
//       ]);

//       const filteredProducts = productResponse
//         .filter(spkm =>
//           spkm.tenSanPham?.toLowerCase().includes(searchTerm.toLowerCase()) && spkm.qrCode
//         )
//         .map(spkm => ({
//           qrCode: spkm.qrCode,
//           tenSanPham: spkm.tenSanPham,
//           maSanPham: spkm.maSanPham,
//           giamGia: spkm.giamGia
//         }));

//       const filteredCategories = categoryResponse.map(category => ({
//         maLoaiSanPham: category?.maLoaiSanPham,
//         tenLoaiSanPham: category?.tenLoaiSanPham
//       }));

//       const filteredBrands = brandResponse.map(brand => ({
//         maThuongHieu: brand?.maThuongHieu,
//         tenThuongHieu: brand?.tenThuongHieu
//       }));

//       // Ensure unique filtered product, category, and brand details
//       const uniqueFilteredProducts = Array.from(new Set(filteredProducts.map(product => product?.maSanPham)))
//         .map(maSanPham => filteredProducts.find(product => product?.maSanPham === maSanPham));

//       // Replace sanPhams, loaiSanPhams, and thuongHieus in each event with the filtered details
//       const modifiedResponse = response.map(event => ({
//         ...event,
//         sanPhams: uniqueFilteredProducts.filter(product => event.sanPhams.includes(product?.maSanPham)),
//         loaiSanPhams: filteredCategories,
//         thuongHieus: filteredBrands
//       }));

//       return modifiedResponse;
//     } catch (error) {
//       console.error("Error in searchCampaigns:", error);
//       throw error;
//     }
//   }
// );


export const sortCampaignsById = createAsyncThunk(
  "campaign/sortCampaignsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await CampaignsService.sortCampaignsById(order, pageNumber, pageSize);
    return response;
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async ({ data }) => {
    const response = await CampaignsService.createCampaign(data);
    return response;
  }
);


export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async ({ id, data }) => {
    const response = await CampaignsService.updateCampaign(id, data);
    return response;
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/deleteCampaign",
  async ({ id }) => {
    await CampaignsService.deleteCampaign(id);
    return id; // Trả về campaignId sau khi đã xóa
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedCampaigns = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.campaigns = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchCampaigns.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchCampaigns.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(searchCampaigns.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortCampaignsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortCampaignsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.campaigns = action.payload;
      })
      .addCase(sortCampaignsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCampaign.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCampaign.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCampaign.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteCampaign.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = campaignSlice;
export default reducer;

export const { clearSelectedRows } = campaignSlice.actions;
export const { setSelectedRowKeys } = campaignSlice.actions;
export const { setSelectedRows } = campaignSlice.actions;
