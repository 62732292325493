import React from 'react';
import { IconButton } from '@mui/material';
import TiktokLogo from './image/tiktok.png';

const TiktokIcon = ({ url }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleClick} style={{ width: 24, height: 24, padding: 0, marginRight: "7px" }}>
      <img src={TiktokLogo} alt="Tiktok" style={{ width: 24, height: 24 }} />
    </IconButton>
  );
};

export default TiktokIcon;
