import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import InventorysService from "~services/shoppingService/inventory_api";


  
const initialState = {
  inventorys: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedInventorys: [],
  errorMessage: [],
}
export const fetchInventorys = createAsyncThunk(
  "inventory/fetchInventorys",
  async () => {
    const response = await InventorysService.fetchInventorys();
    return response;
  }
);

export const searchInventorys = createAsyncThunk(
  "inventory/searchInventorys",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await InventorysService.searchInventorys(searchTerm, pageNumber, pageSize);
    return response;
  }
);

export const sortInventorysById = createAsyncThunk(
  "inventory/sortInventorysById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await InventorysService.sortInventorysById(order, pageNumber, pageSize);
    return response;
  }
);

export const createInventory = createAsyncThunk(
  "inventory/createInventory",
  async ({data}) => {
    const response = await InventorysService.createInventory(data);
    return response;
  }
);


export const updateInventory = createAsyncThunk(
  "inventory/updateInventory",
  async ({ id, data }) => {
    const response = await InventorysService.updateInventory(id, data);
    return response;
  }
);

export const deleteInventory = createAsyncThunk(
  "inventory/deleteInventory",
  async ({id}) => {
    await InventorysService.deleteInventory(id);
    return id; // Trả về inventoryId sau khi đã xóa
  }
);

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedInventorys = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.inventorys = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInventorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.inventorys = action.payload;
      })
      .addCase(fetchInventorys.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchInventorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchInventorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.inventorys = action.payload;
      })
      .addCase(searchInventorys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortInventorysById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortInventorysById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.inventorys = action.payload;
      })
      .addCase(sortInventorysById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createInventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createInventory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createInventory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateInventory.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateInventory.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateInventory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteInventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteInventory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteInventory.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = inventorySlice;
export default reducer;

export const { clearSelectedRows } = inventorySlice.actions;
export const { setSelectedRowKeys } = inventorySlice.actions;
export const { setSelectedRows } = inventorySlice.actions;
