import React from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const FileInput = ({ onFileChange, disabled }) => {
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <>
            <input
                type="file"
                accept="image/*"
                onChange={onFileChange}
                disabled={disabled}
                style={{ display: 'none' }}
                id="fileInput"
            />
            <Button
                icon={<UploadOutlined />}
                onClick={triggerFileInput}
                disabled={disabled}
            >
                Gửi ảnh
            </Button>
        </>
    );
};

export default FileInput;
