import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, editQuantity, toggleCartPopup } from '~redux/slices/shopping/cartSlice';
import { RootState } from '~redux/store';
import { useDetectClickOutside } from "react-detect-click-outside";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Iconify from '~components/image/iconify/iconify.tsx';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';



//--------------------------CartPreview--------------------------//
// @ts-ignore
const CartPreview = ({ closeCart }) => {

    //@ts-ignore
    const viewLanguage = useSelector(state => state.common.language);
    useEffect(() => {
        setLanguage(viewLanguage || "vn");
    }, [viewLanguage]);

    const LANG: { [key: string]: { [key: string]: string } } = {
        add: { vn: "THANH TOÁN", en: "Checkout", jp: "チェックアウト", kr: "점검", cn: "查看", de: "Auschecken" },
        total: { vn: "Tổng cộng", en: "Total", jp: "合計", kr: "합계", cn: "总", de: "Gesamt" },
    };

    const [language, setLanguage] = useState("vn");
    const [add, total] = [LANG.add[language as keyof typeof LANG.add], LANG.total[language as keyof typeof LANG.total]];
    useEffect(() => {
        setLanguage(viewLanguage || "vn");
    }, [viewLanguage]);


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ref = useDetectClickOutside({ onTriggered: closeCart });
    const dispatch = useDispatch();


    // const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);

    const cartItems = useSelector((state: RootState) => state.cart.items);
    // @ts-ignore
    const filteredCartItems = cartItems.filter(item => !item.isMuaSau);

    const cartTotal = cartItems.reduce((total, item) => {
        // @ts-ignore
        if (!item.isMuaSau) {
            // @ts-ignore
            const donGia = item?.donGia ?? 0;
            // @ts-ignore
            const soLuong = item.soLuong ?? 0;
            return total + donGia * soLuong;
        }
        return total;
    }, 0);

    const handleRemove = (maSanPham: any) => {
        // @ts-ignore
        dispatch(removeFromCart(maSanPham));
    };

    const handleQuantityChange = (maSanPham: any, soLuong: any) => {
        if (soLuong >= 0) {
            // @ts-ignore
            dispatch(editQuantity({ maSanPham, soLuong }));
        }
    };

    const navigate = useNavigate();
    const handleProceedCheckout = () => {
        dispatch(toggleCartPopup());
        navigate('/shopping/cart');
    };

    const productImage = (item: any) => {
        const url = item?.anhMinhHoa ?? process.env.REACT_APP_DEFAULT_IMAGE;
        return url;
    }


    return (
        <div style={{ position: 'fixed', top: '60px', right: '10px', background: "white", border: "0.5px solid #d9d9d9", borderRadius: "15px" }}>
            <List sx={{ height: 420, overflowY: 'auto', width: 370, bgcolor: 'white' }} style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                {filteredCartItems.map((item) => (
                    <ListItem key={item.maSanPham?.toString()} style={{ paddingRight: 0, paddingLeft: 10 }}>
                        <ListItemAvatar>
                            <img
                                style={{ width: 50, height: 50, borderRadius: 15, objectFit: 'cover' }}
                                src={productImage(item)}
                                alt={item?.tenSanPham}
                            />
                        </ListItemAvatar>

                        <ListItemText
                            style={{
                                maxWidth: 230,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            primary={<span>{item?.tenSanPham}</span>}
                            secondary={`${(item?.donGia ?? 0).toLocaleString('vi-VN')} đ`}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: 90, marginRight: "5px" }}>
                            <select
                                value={item.soLuong}
                                onChange={(e) => handleQuantityChange(item.maSanPham, parseInt(e.target.value))}
                                style={{
                                    margin: "10px",
                                    color: 'black',
                                    backgroundColor: 'white',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    height: '25px',
                                    textAlignLast: 'center',
                                }}
                            >
                                {[...Array(50).keys()].map((number) => (
                                    <option key={number + 1} value={number + 1}>
                                        {number + 1}
                                    </option>
                                ))}
                            </select>

                            <IconButton
                                style={{ margin: 0, padding: 0, marginLeft: "0px" }}
                                onClick={() => handleRemove(item.maSanPham)}>
                                {/* @ts-ignore */}
                                <Iconify icon="eva:close-outline" />
                            </IconButton>
                        </Box>

                    </ListItem>
                ))}
            </List>
            <Typography sx={{ textAlign: "right", m: 2 }}>{total}: {cartTotal.toLocaleString('vi-VN')} đ</Typography>
            <Button fullWidth variant="contained" onClick={handleProceedCheckout} style={{ borderRadius: "2px" }}>
                {add}
            </Button>
        </div>
    );
}

export default CartPreview;
