import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Iconify from '~components/image/iconify/iconify.tsx';
import Badge from '@mui/material/Badge';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '~redux/store';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logoutSuccess, toggleRememberMe } from "~redux/slices/auth/authSlice";
import { syncCartOnLogOut } from '~redux/slices/shopping/cartSlice';
import useAuth from '~hooks/auth/useAuth';
import { useTranslation } from 'react-i18next';


//@ts-ignore
export default function AccountPopover({ menuControl }) {
    const [open, setOpen] = useState(null);
    const newMessagesCount = useSelector((state: RootState) => state.common.newMessagesCount);

    const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
    const { isLogIn, userInfo, isCEO, isSeller } = useAuth();

    const { t, i18n } = useTranslation();
    useEffect(() => {
        const language = localStorage.getItem('language');
        if (language) {
            i18n.changeLanguage(language);
        } else {
            i18n.changeLanguage('vn');
        }
    }, [i18n]);

    const MENU_OPTIONS = [

        ...(isSeller ? [
            {
                path: "/seller",
                label: ("Bán hàng"),
                icon: "healthicons:contact-support-outline",
            },
        ] : []),

        {
            path: "/userProfile/general",
            label: t('accountPopover.userProfile'),
            icon: "healthicons:ui-user-profile-outline",
        },
        {
            path: "/liked_item",
            label: t('accountPopover.likedItem'),
            icon: "solar:chat-round-like-outline",
        },
        {
            path: "/order_history",
            label: t('accountPopover.orderHistory'),
            icon: "carbon:product",
        },
        ...(isCEO ? [
            {
                path: "/ceo/chat",
                label: (
                    <>
                        {t('accountPopover.chat')}
                        <Badge color="error" badgeContent={newMessagesCount} style={{ marginLeft: '18px' }} />
                    </>
                ),
                icon: "healthicons:contact-support-outline",
            },
            {
                path: "/ceo",
                label: t('accountPopover.ceo'),
                icon: "carbon:settings-edit",
            }

        ] : []),

    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleNavigateToLogin = () => {
        setOpen(null);
        navigate('/auth/signIn');
    };

    //@ts-ignore
    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogOut = async () => {
        setOpen(null);

        //@ts-ignore
        await dispatch(syncCartOnLogOut()).unwrap();
        dispatch(logoutSuccess());

        window.location.href = '/';
    };

    //@ts-ignore
    const handleClickMenu = (option) => {
        setOpen(null);
        navigate(`${option.path}`);
    };

    return (
        <>
            <IconButton
                disabled={isCartOpen}
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    //@ts-ignore
                    ...(open && {
                        background: (theme) =>
                            `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    }),
                }}
            >
                <Avatar
                    src={
                        !isLogIn ?
                            `${process.env.PUBLIC_URL}/assets/images/avatars/guest_avatar2.svg`
                            : !isCEO ?
                                `${process.env.PUBLIC_URL}/assets/images/avatars/user_avatar3.svg`
                                : `${process.env.PUBLIC_URL}/assets/images/avatars/avatar_12.jpg`
                    }
                    alt={(userInfo as { userName: string }).userName}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {typeof userInfo === 'object' && userInfo && 'userName' in userInfo && userInfo.userName.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {isLogIn ? (userInfo as { userName: string }).userName : "User chưa đăng nhập"}
                    </Typography>
                </Box>

                {menuControl ? (
                    <div>
                        <Divider sx={{ borderStyle: 'dashed' }} />

                        {
                            MENU_OPTIONS.map((option) => (
                                //@ts-ignore
                                <MenuItem key={option.label} onClick={() => handleClickMenu(option)}>
                                    <div style={{ marginRight: "15px" }}>
                                        <Iconify
                                            //@ts-ignore
                                            icon={option.icon}
                                            width={24}
                                            height={24}
                                        ></Iconify>
                                    </div>
                                    {option.label}
                                </MenuItem>
                            ))
                        }

                        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

                        <MenuItem
                            disableRipple
                            disableTouchRipple
                            onClick={handleLogOut}
                            sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
                        >
                            {t('accountPopover.logOut')}
                        </MenuItem>
                    </div>
                ) : (
                    <MenuItem
                        disableRipple
                        disableTouchRipple
                        onClick={handleNavigateToLogin}
                        sx={{ typography: 'subtitle1', color: 'royalblue', py: 1.5 }}
                    >
                        {t('accountPopover.logIn')}
                    </MenuItem>
                )}
            </Popover>
        </>
    );
}
