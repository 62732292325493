import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { alpha } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { usePathname } from '~routes/hooks/use-pathname.tsx';
import RouterLink from '~routes/components/router-link.tsx';
import { useResponsive } from '~hooks/use-responsive.tsx';
import Logo from '~components/common/logo/logo.tsx';
import Scrollbar from '~components/common/scrollbar/scrollbar.tsx';
import { NAV } from '../component/config-layout.tsx';
import ProductCategoryList from "~layouts/commonLayout/list.tsx";
import SvgColor from '~components/theme/svg-color/svg-color.tsx';
import { useTranslation } from 'react-i18next';



// @ts-ignore
const Nav = ({ openNav, onCloseNav, menuControl }) => {


    const { t, i18n } = useTranslation();
    useEffect(() => {
        const language = localStorage.getItem('language');
        if (language) {
            i18n.changeLanguage(language);
        } else {
            i18n.changeLanguage('vn');
        }
    }, [i18n]);


    // @ts-ignore
    const icon = (name) => (
        // @ts-ignore
        <SvgColor src={process.env.PUBLIC_URL + `/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
    );


    const navConfig = [
        //User
        {
            menuControl: true,
            items: [
                {
                    title: t("nav.home"),
                    path: '/home',
                    icon: icon('ic_home'),
                },
                {
                    title: t("nav.blog"),
                    path: '/blog',
                    icon: icon('ic_blog'),
                },
                {
                    title: t("nav.shop"),
                    path: '/shopping',
                    icon: icon('ic_cart'),
                },
            ]
        },

        //Guest
        {
            menuControl: false,
            items: [
                {
                    title: t("nav.home"),
                    path: '/home',
                    icon: icon('ic_home'),
                },
                {
                    title: t("nav.blog"),
                    path: '/blog',
                    icon: icon('ic_blog'),
                },
                {
                    title: t("nav.shop"),
                    path: '/shopping',
                    icon: icon('ic_cart'),
                },
            ]
        },
    ];



    const navConfig2 = [
        {
            //User
            menuControl: true,
            items: [
                {
                    title: t("nav-config.orderhistory"),
                    path: '/order_history',
                    icon: icon('ic_order_history'),
                },
                {
                    title: t("nav-config.likeditem"),
                    path: '/liked_item',
                    icon: icon('ic_product_like'),
                },
            ]
        },

        //Guest
        {
            menuControl: false,
            items: [
                {
                    title: t("common.signIn"),
                    path: '/auth/signIn',
                    icon: icon('ic_lock'),
                },
                {
                    title: t("common.signUp"),
                    path: '/auth/signUp',
                    icon: icon('ic_register'),
                },
            ]
        },
    ];





    const pathname = usePathname();
    // @ts-ignore
    const upLg = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderNavMenu = (
        <Stack direction={"row"} component="nav" spacing={0.5} sx={{ px: 2 }}>
            {navConfig.map((group) => (
                group.menuControl === menuControl && group.items.map((item) => (
                    <NavHomeItem key={item.title} item={item} />
                ))
            ))}
        </Stack>
    );

    const renderCategoryMenu = (
        <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
            {navConfig2.map((group) => (
                group.menuControl === menuControl && group.items.map((item) => (
                    <NavItem key={item.title} item={item} />
                ))
            ))}
        </Stack>
    );


    const handleIconClick = () => {
        onCloseNav();
    };


    const renderContent = (
        <
            // @ts-ignore
            Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <
                // Logo Nostones
                // @ts-ignore
                Logo sx={{ mt: 3, ml: 4 }} />
            <Box
                sx={{
                    my: 2, mx: 0.5, py: 1, px: 0,
                    display: 'flex',
                    borderRadius: 1.5,
                    alignItems: 'center',
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
                }}
            >
                {renderNavMenu}
            </Box>
            {renderCategoryMenu}

            {(pathname === "/shopping" || pathname === "/") && <ProductCategoryList onIconClick={handleIconClick} />}

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <>
            <Box
                sx={{
                    flexShrink: { lg: 0 },
                    width: { lg: NAV.WIDTH },
                    overflowX: 'hidden',
                }}
            >
                {upLg ? (
                    <Box
                        sx={{
                            height: 1,
                            position: 'fixed',
                            width: NAV.WIDTH,
                            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                            overflowX: 'hidden',
                        }}
                    >
                        {renderContent}
                    </Box>
                ) : (
                    <Drawer
                        open={openNav}
                        onClose={onCloseNav}
                        PaperProps={{
                            sx: {
                                width: NAV.WIDTH,
                                overflowX: 'hidden',
                            },
                        }}
                    >
                        {renderContent}
                    </Drawer>
                )}
            </Box>
        </>
    );
}

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};



// ----------------------------------------------------------------------

function NavHomeItem(
    // @ts-ignore
    { item }) {
    const pathname = usePathname();
    const active = item.path === pathname;

    return (
        // @ts-ignore
        <ListItemButton
            component={RouterLink}
            href={item.path}
            target={item.target || '_self'}
            rel={item.target === '_blank' ? 'noopener noreferrer' : undefined}
            sx={{
                overflowX: 'hidden',
                display: "flex",
                flexDirection: "column",
                alignItems: 'center',
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{ width: 24, height: 24, mr: 2, marginLeft: '7px', }}>
                {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>

        </ListItemButton>
    );
}

function NavItem(
    // @ts-ignore
    { item }) {
    const pathname = usePathname();
    const active = item.path === pathname;

    return (
        // @ts-ignore
        <ListItemButton
            component={RouterLink}
            href={item.path}
            sx={{
                minHeight: 44,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
                {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>

        </ListItemButton>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
};

NavHomeItem.propTypes = {
    item: PropTypes.object,
};

export default Nav