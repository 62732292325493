import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Detail_InventorysService from "~services/shoppingService/detail_inventory_api";


  
const initialState = {
  detail_inventorys: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedDetail_Inventorys: [],
  errorMessage: [],
}
export const fetchDetail_Inventorys = createAsyncThunk(
  "detail_inventory/fetchDetail_Inventorys",
  async () => {
    const response = await Detail_InventorysService.fetchDetail_Inventorys();
    return response;
  }
);

export const searchDetail_Inventorys = createAsyncThunk(
  "detail_inventory/searchDetail_Inventorys",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await Detail_InventorysService.searchDetail_Inventorys(searchTerm, pageNumber, pageSize);
    return response;
  }
);

export const sortDetail_InventorysById = createAsyncThunk(
  "detail_inventory/sortDetail_InventorysById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await Detail_InventorysService.sortDetail_InventorysById(order, pageNumber, pageSize);
    return response;
  }
);

export const createDetail_Inventory = createAsyncThunk(
  "detail_inventory/createDetail_Inventory",
  async ({data}) => {
    const response = await Detail_InventorysService.createDetail_Inventory(data);
    return response;
  }
);


export const updateDetail_Inventory = createAsyncThunk(
  "detail_inventory/updateDetail_Inventory",
  async ({ id, data }) => {
    const response = await Detail_InventorysService.updateDetail_Inventory(id, data);
    return response;
  }
);

export const deleteDetail_Inventory = createAsyncThunk(
  "detail_inventory/deleteDetail_Inventory",
  async ({id}) => {
    await Detail_InventorysService.deleteDetail_Inventory(id);
    return id; // Trả về detail_inventoryId sau khi đã xóa
  }
);

const detail_inventorySlice = createSlice({
  name: "detail_inventory",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedDetail_Inventorys = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.detail_inventory = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetail_Inventorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDetail_Inventorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.detail_inventory = action.payload;
      })
      .addCase(fetchDetail_Inventorys.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchDetail_Inventorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchDetail_Inventorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.detail_inventory = action.payload;
      })
      .addCase(searchDetail_Inventorys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortDetail_InventorysById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortDetail_InventorysById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.detail_inventory = action.payload;
      })
      .addCase(sortDetail_InventorysById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createDetail_Inventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createDetail_Inventory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createDetail_Inventory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateDetail_Inventory.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateDetail_Inventory.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateDetail_Inventory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteDetail_Inventory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteDetail_Inventory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteDetail_Inventory.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = detail_inventorySlice;
export default reducer;

export const { clearSelectedRows } = detail_inventorySlice.actions;
export const { setSelectedRowKeys } = detail_inventorySlice.actions;
export const { setSelectedRows } = detail_inventorySlice.actions;
