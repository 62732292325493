import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Hook để xử lý responsive dựa trên query cụ thể
//@ts-ignore
export function useResponsive(query, start, end) {
  const theme = useTheme();

  // Sử dụng Media Query để xác định kích thước màn hình
  const mediaUp = useMediaQuery(theme.breakpoints.up(start));
  const mediaDown = useMediaQuery(theme.breakpoints.down(end)); 
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));

  // Trả về giá trị boolean tương ứng với query được chọn
  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  // Mặc định trả về mediaOnly nếu không phải các trường hợp trên
  return mediaOnly;
}

// ----------------------------------------------------------------------

// Hook để xác định kích thước màn hình hiện tại
export function useWidth() {
  const theme = useTheme();

  // Lấy tất cả breakpoints và đảo ngược để kiểm tra từ lớn đến nhỏ
  // const keys = [...theme.breakpoints.keys].reverse();
  const [width, setWidth] = useState('xs'); // Mặc định kích thước là 'xs'

  // Sử dụng Media Query để kiểm tra kích thước màn hình
  const matchXl = useMediaQuery(theme.breakpoints.up('xl'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchXs = useMediaQuery(theme.breakpoints.up('xs'));

  useEffect(() => {
    // Cập nhật kích thước màn hình dựa trên breakpoint phù hợp nhất
    if (matchXl) setWidth('xl');
    else if (matchLg) setWidth('lg');
    else if (matchMd) setWidth('md');
    else if (matchSm) setWidth('sm');
    else setWidth('xs'); // Nếu không match bất kỳ cái nào, mặc định là 'xs'
  }, [matchXl, matchLg, matchMd, matchSm, matchXs]);

  return width; // Trả về kích thước màn hình hiện tại
}
