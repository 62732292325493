import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchInventorys = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/PhieuNhapKho?sort=maPhieuNhapKho,asc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchInventorys = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/PhieuNhapKho?sort=maPhieuNhapKho,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Inventorys');
    }
};

const sortInventorysById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maPhieuNhapKho,asc' : 'maPhieuNhapKho,desc';
        const response = await api.get(`/PhieuNhapKho?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Inventorys by id');
    }
};

const createInventory = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/PhieuNhapKho', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Inventorys failed');
    }
};


const updateInventory = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/PhieuNhapKho/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Inventorys failed');
    }
};

const deleteInventory = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/PhieuNhapKho/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Inventorys deleted successfully';
    } catch (error) {
        throw new Error('Deleting Inventorys failed');
    }
};

const InventorysService = {
    fetchInventorys,
    searchInventorys,
    sortInventorysById,
    createInventory,
    updateInventory,
    deleteInventory
}
export default InventorysService;