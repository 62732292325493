import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '~redux/store';
import { useDispatch } from 'react-redux';
import { setLanguage } from '~redux/slices/common/commonSlice';

const LANGS = [
    {
        value: 'vn',
        label: 'Tiếng Việt',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_vn.svg',
    },
    {
        value: 'en',
        label: 'English',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_en.svg',
    },
    {
        value: 'jp',
        label: '日本語',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_jp.svg',
    },
    {
        value: 'kr',
        label: '한국인',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_kr.svg',
    },
    {
        value: 'de',
        label: 'Deutsch',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_de.svg',
    },
    {
        value: 'cn',
        label: '中文',
        icon: process.env.PUBLIC_URL + '/assets/icons/ic_flag_cn.svg',
    }
];

export default function LanguagePopover() {
    const [open, setOpen] = useState<HTMLElement | null>(null);
    const [currentLang, setCurrentLang] = useState<string>(localStorage.getItem('language') || 'vn');
    const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
    const { i18n } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, [currentLang, i18n]);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLanguageChange = async (lang: string) => {
        setCurrentLang(lang);
        //@ts-ignore
        dispatch(setLanguage(lang));
        localStorage.setItem('language', lang);
        handleClose();
    };

    return (
        <>
            <IconButton
                disabled={isCartOpen}
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {
                        bgcolor: 'action.selected',
                    }),
                }}
            >
                <img src={LANGS.find(lang => lang.value === currentLang)?.icon || LANGS[0].icon} alt={LANGS.find(lang => lang.value === currentLang)?.label || LANGS[0].label} />
            </IconButton>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 180,
                    },
                }}
            >
                {LANGS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === currentLang}
                        onClick={() => handleLanguageChange(option.value)}
                        sx={{ typography: 'body2', py: 1 }}
                    >
                        <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
}
