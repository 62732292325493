import { useMemo } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { palette } from './palette.tsx';
import { shadows } from './shadows.tsx';
import { overrides } from './overrides.tsx';
import { typography } from './typography.tsx';
import { customShadows } from './custom-shadows.tsx';

// ----------------------------------------------------------------------

// @ts-ignore
export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    []
  );
  // @ts-ignore
  const theme = createTheme(memoizedValue);
  // @ts-ignore
  theme.components = overrides(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
