import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import RouterLink from '~components/common/router-link/router-link.tsx';
import { useDispatch } from 'react-redux';
import { resetFilter } from '~redux/slices/common/commonSlice';


// @ts-ignore
const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const dispatch = useDispatch();

  const currentPath = window.location.pathname;
  let pathToReturn = '/';

  if (currentPath.startsWith('/shopping')) {
    pathToReturn = '/shopping';
    dispatch(resetFilter());
  } else if (currentPath.startsWith('/blog')) {
    pathToReturn = '/blog';
  } else if (currentPath.startsWith('/ceo')) {
    pathToReturn = '/ceo';
  } else {
    pathToReturn = '/';
    dispatch(resetFilter());
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const logo = (
    <Box
      component="img"
      src={process.env.PUBLIC_URL + "/logo21.png"}
      sx={{ width: 215, height: 70, cursor: 'pointer', ...sx }}
      onClick={handleClick}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <>
      <Helmet>
        <title> Nostones </title>
      </Helmet>

      {/* @ts-ignore */}
      <Link
        component={RouterLink}
        href={pathToReturn}
        sx={{ display: 'contents' }}
        onClick={handleClick}
      >
        {logo}
      </Link>
    </>
  );
});

Logo.propTypes = {
  // @ts-ignore
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;