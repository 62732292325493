import axios from 'axios';

// Khởi tạo client Axios với cấu hình mặc định
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
    withCredentials: true, // Cho phép gửi cookie cùng với các request
    responseType: 'json', // Thiết lập kiểu dữ liệu trả về là JSON
    timeout: 30000, // Thiết lập thời gian tối đa cho một request là 30s
});

// Interceptor xử lý trước khi gửi request
axiosClient.interceptors.request.use(config => {


    // Xóa các header không cần thiết
    // delete config.headers['Dnt'];
    // delete config.headers['Referer'];
    // delete config.headers['Sec-Ch-Ua'];
    // delete config.headers['Sec-Ch-Ua-Mobile'];
    // delete config.headers['Sec-Ch-Ua-Platform'];
    // delete config.headers['User-Agent'];

    return config;
}, error => {
    // Xử lý khi request bị lỗi trước khi được gửi đi
    console.error('Lỗi trong khi gửi request:', error);
    return Promise.reject(error);
});

// Interceptor xử lý sau khi nhận phản hồi
axiosClient.interceptors.response.use(response => {
    // Trả về dữ liệu trực tiếp
    return response;

}, error => {
    // Xử lý các lỗi từ phía server
    if (error.response) {
        console.error('Lỗi phản hồi:', error.response);
        // Kiểm tra nếu lỗi liên quan đến xác thực và làm mới token
        if (error.response.code === 8 && !error.config._retry) {
            error.config._retry = true;
            // Gửi request để làm mới token
            return axiosClient.post(`${process.env.REACT_APP_BACKEND}/auth/refresh`)
                .then(res => {
                    if (res.data.token) {
                        // Cập nhật token mới vào cookie qua server
                        document.cookie = `token=${res.data.token}; Secure; HttpOnly; Path=/;`;
                        error.config.headers.Authorization = `Bearer ${res.data.token}`;
                        return axiosClient(error.config);
                    }
                })
                .catch(err => {
                    console.error('Lỗi khi làm mới token:', err);
                    return Promise.reject(err);
                });
        }
        // Rethrow các lỗi khác về server
        return Promise.reject(error.response.data);
    } else if (error.request) {
        // Lỗi xảy ra khi không nhận được phản hồi từ server
        return Promise.reject({ message: 'Server không phản hồi' });
    } else {
        // Lỗi khác
        return Promise.reject({ message: error.message });
    }
});

export default axiosClient;
