import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List, Badge, Avatar, Divider, Tooltip, Popover, Typography, IconButton, ListItemText, ListSubheader, ListItemAvatar, ListItemButton, Button } from '@mui/material';
import Iconify from '~components/image/iconify/iconify.tsx';
import Scrollbar from '~components/common/scrollbar/scrollbar.tsx';
import { useSelector } from 'react-redux';
import orderAPI, { sendReadNotification } from '~services/shoppingService/order_api'; // Import hàm sendReadNotification

import order_placed from '~layouts/z_other/image/order_placed.png';
import order_confirmed from '~layouts/z_other/image/order_confirmed.png';
import order_shipped from '~layouts/z_other/image/order_shipped.png';
import order_shipCompleted from '~layouts/z_other/image/order_shipCompleted.png';
import order_cancelled from '~layouts/z_other/image/order_cancelled.png';
import { fToNow_VietNam } from '../../components/utils/format-time';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

// @ts-ignore
export default function NotificationsPopover({ menuControl }) {
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);

  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();

  // @ts-ignore
  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const allOrders = useSelector((state) => state.order.orders) || [];

  const [open, setOpen] = useState(null);

  function convertDateStr(dateStr) {
    const parts = dateStr.split(' ');
    const dateParts = parts[0].split('/');
    const timePart = parts[1];
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${timePart}`;
  }

  const fetchOrderStatus = async (maDonHang) => {
    try {
      const response = await orderAPI.getOrderStatus(maDonHang);
      // console.log("response:", response);
      return response;
    } catch (error) {
      console.error('Failed to fetch order status:', error);
      return [];
    }
  };

  const fetchNotifications = async () => {
    try {
      if (!Array.isArray(allOrders)) {
        console.error("allOrders is not an array");
        return;
      }

      const data = await Promise.all(allOrders.map(async (order) => {
        // console.log("order:", order);

        const statuses = await fetchOrderStatus(order.maDonHang);

        // console.log("statuses:", statuses);

        const latestStatus = statuses[statuses.length - 1]; // assuming the latest status is the last one

        // console.log("latestStatus:", latestStatus);

        return {
          id: order.maHoaDon,
          maDonHang: order.maDonHang,
          title: `Thông báo đơn hàng #${order.maHoaDon}`,
          description: getOrderStatusDescription(latestStatus.trangThai),
          avatar: null,
          type: getOrderType(latestStatus.trangThai),
          createdAt: new Date(convertDateStr(order.ngayDat)),
          isUnRead: !latestStatus.isUnRead
        };
      }));

      setNotifications(data);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  // @ts-ignore
  const getOrderStatusDescription = (status) => {
    switch (status) {
      case "ChoDuyet":
        return "Đơn hàng đang chờ duyệt";
      case "DaDuyet":
        return "Đơn hàng đã được duyệt";
      case "DangGiao":
        return "Đơn hàng đang được giao";
      case "GiaoThanhCong":
        return "Đơn hàng đã giao thành công";
      case "Huy":
        return "Đơn hàng đã hủy";
      default:
        return "Trạng thái đơn hàng không xác định";
    }
  };

  // @ts-ignore
  const getOrderType = (status) => {
    switch (status) {
      case "ChoDuyet":
        return "order_placed";
      case "DaDuyet":
        return "order_confirmed";
      case "DangGiao":
        return "order_shipped";
      case "GiaoThanhCong":
        return "order_shipCompleted";
      case "Huy":
        return "order_cancelled";
      default:
        return "order_shipped";
    }
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  // @ts-ignore
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };



  const handleMarkAllAsRead = async () => {
    setNotifications(
      // @ts-ignore
      notifications.map((notification) => ({
        // @ts-ignore
        ...notification,
        isUnRead: false,
      }))
    );

    try {
      // Gửi API: đánh dấu tất cả là đã đọc
      await Promise.all(notifications.map(notification =>
        sendReadNotification({
          maDonHang: notification.maDonHang, // Sử dụng maDonHang thay vì id
          trangThai: convertStatusToAPI(notification.type), // Chuyển đổi type sang trạng thái API
        })
      ));
    } catch (error) {
      console.error("Failed to send read notification:", error);
    }
  };

  const handleReaded = async (notificationId) => {
    const notification = notifications.find((item) => item.id === notificationId);
    if (notification && notification.isUnRead) {
      notification.isUnRead = false;
      setNotifications([...notifications]);

      try {
        // Gửi API: đánh dấu đã đọc thông báo
        await sendReadNotification({
          maDonHang: notification.maDonHang, // Sử dụng maDonHang thay vì id
          trangThai: convertStatusToAPI(notification.type), // Chuyển đổi type sang trạng thái API
        });
      } catch (error) {
        console.error("Failed to send read notification:", error);
      }
    }
  };

  // Hàm chuyển đổi từ type sang trạng thái API
  const convertStatusToAPI = (type) => {
    switch (type) {
      case "order_placed":
        return "ChoDuyet";
      case "order_confirmed":
        return "DaDuyet";
      case "order_shipped":
        return "DangGiao";
      case "order_shipCompleted":
        return "GiaoThanhCong";
      case "order_cancelled":
        return "Huy";
      default:
        return "Unknown";
    }
  };


  const handleShowAll = () => {
    try {
      handleClose();
      handleMarkAllAsRead();
      navigate("/showAllNotifycation", { state: { notifications } });
    } catch (error) {
      console.error("Failed to mark all notifications as read or navigate:", error);
    }
  }


  return (
    <>
      <IconButton
        disabled={isCartOpen}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify width={24} icon="solar:bell-bing-bold-duotone" />
        </Badge>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            overflowY: 'auto',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Thông báo</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Bạn có {totalUnRead} thông báo chưa đọc
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Đánh dấu tất cả là đã đọc">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Thông báo mới
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={() => handleReaded(notification.id)} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Thông báo đã đọc
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={() => handleReaded(notification.id)} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={handleShowAll}
          >
            Xem tất cả
          </Button>
        </Box>

      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onClick }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={onClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow_VietNam(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src={order_placed} style={{ width: 'auto', height: 'auto' }} />,
      title,
    };
  }

  if (notification.type === 'order_confirmed') {
    return {
      avatar: <img alt={notification.title} src={order_confirmed} style={{ width: 'auto', height: 'auto' }} />,
      title,
    };
  }

  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src={order_shipped} style={{ width: 'auto', height: 'auto' }} />,
      title,
    };
  }

  if (notification.type === 'order_shipCompleted') {
    return {
      avatar: <img alt={notification.title} src={order_shipCompleted} style={{ width: 'auto', height: 'auto' }} />,
      title,
    };
  }

  if (notification.type === 'order_cancelled') {
    return {
      avatar: <img alt={notification.title} src={order_cancelled} style={{ width: 'auto', height: 'auto' }} />,
      title,
    };
  }

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
