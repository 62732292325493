import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { NAV, HEADER } from './config-layout.tsx';


const SPACING = 8;


// @ts-ignore
export default function Main({ children, sx, ...other }) {
  // @ts-ignore
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE + SPACING}px`, // chỉ đặt padding trên
        ...(lgUp && {
          px: 2,
          pt: `${HEADER.H_DESKTOP + SPACING}px`, // chỉ đặt padding trên
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
