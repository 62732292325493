import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const CHECKOUT_STEPS = {
    AUTH: "auth",
    SHIPPING: "shipping",
    PAYMENT: "payment",
};

const initialState = {
    step: CHECKOUT_STEPS.AUTH,
    shippingAddress: null,
};

// Thunk để lưu shipping address vào LocalStorage
export const saveShippingAddressLocally = createAsyncThunk(
    "checkout/saveShippingAddressLocally",
    async (shippingAddress, { dispatch }) => {
        // Lưu shipping address vào LocalStorage
        localStorage.setItem("checkout", JSON.stringify(shippingAddress));
        // Dispatch action to update state
        dispatch(setShippingAddress({ shippingAddress }));
    }
);

const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setCheckoutStep: (state, action) => {
            state.step = action.payload.step;
        },
        setShippingAddress: (state, action) => {
            state.shippingAddress = action.payload.shippingAddress;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(saveShippingAddressLocally.fulfilled, (state, action) => {
            // Không cần thay đổi state vì đã xử lý trong reducer setShippingAddress
        });
    },
});

export const { setCheckoutStep, setShippingAddress } = checkoutSlice.actions;

export default checkoutSlice.reducer;
