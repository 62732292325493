import SvgColor from '~components/theme/svg-color/svg-color.tsx';


// @ts-ignore
const icon = (name) => (
    // @ts-ignore
    <SvgColor src={process.env.PUBLIC_URL + `/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
    {
        title: 'Home',
        path: '/',
        icon: icon('ic_home'),
    },
    {
        title: 'Mua hàng',
        path: '/shopping',
        icon: icon('ic_cart'),
    },
    {
        title: 'blog',
        path: '/blog',
        icon: icon('ic_blog'),
    },
    {
        title: 'Trang quản lý',
        path: '',
        icon: icon('ic_analytics'),
    },
    {
        title: 'Phân tích dữ liệu',
        path: '',
        icon: icon('ic_analytics'),
    },
    {
        title: 'Quản lý người dùng',
        path: 'user-manage',
        icon: icon('ic_user_manage'),
    },
    {
        title: 'Quản lý sản phẩm',
        path: 'product-manage',
        icon: icon('ic_product_manage'),
    },
    {
        title: 'Quản lý đơn hàng',
        path: 'order_manage',
        icon: icon('ic_order_manage'),
    },
    {
        title: 'OptionPage',
        path: 'option',
        icon: icon('ic_order_manage'),
    },
    {
        title: 'OptionPage2',
        path: 'tenOption',
        icon: icon('ic_order_manage'),
    },
    {
        title: 'OptionPage3',
        path: 'option3',
        icon: icon('ic_order_manage'),
    },
    {
        title: 'OptionPage4',
        path: 'option4',
        icon: icon('ic_order_manage'),
    },
    {
        title: 'Upload Image',
        path: 'image-upload',
        icon: icon('ic_image_upload'),
    },
];

export default navConfig;
