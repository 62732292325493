import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SuppliersService from "~services/shoppingService/supplier_api";


  
const initialState = {
  suppliers: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedSuppliers: [],
  errorMessage: [],
}
export const fetchSuppliers = createAsyncThunk(
  "supplier/fetchSuppliers",
  async () => {
    const response = await SuppliersService.fetchSuppliers();
    return response;
  }
);

export const searchSuppliers = createAsyncThunk(
  "supplier/searchSuppliers",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await SuppliersService.searchSuppliers(searchTerm, pageNumber, pageSize);
    return response;
  }
);
export const sortSuppliersById = createAsyncThunk(
  "supplier/sortSuppliersById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await SuppliersService.sortSuppliersById(order, pageNumber, pageSize);
    return response;
  }
);

export const createSupplier = createAsyncThunk(
  "supplier/createSupplier",
  async ({data}) => {
    const response = await SuppliersService.createSupplier(data);
    return response;
  }
);


export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async ({ id, data }) => {
    const response = await SuppliersService.updateSupplier(id, data);
    return response;
  }
);

export const deleteSupplier = createAsyncThunk(
  "supplier/deleteSupplier",
  async ({id}) => {
    await SuppliersService.deleteSupplier(id);
    return id; // Trả về supplierId sau khi đã xóa
  }
);

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedSuppliers = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.suppliers = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuppliers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSuppliers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.suppliers = action.payload;
      })
      .addCase(fetchSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchSuppliers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchSuppliers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.suppliers = action.payload;
      })
      .addCase(searchSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortSuppliersById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortSuppliersById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.suppliers = action.payload;
      })
      .addCase(sortSuppliersById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createSupplier.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSupplier.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createSupplier.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateSupplier.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateSupplier.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateSupplier.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteSupplier.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSupplier.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteSupplier.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = supplierSlice;
export default reducer;

export const { clearSelectedRows } = supplierSlice.actions;
export const { setSelectedRowKeys } = supplierSlice.actions;
export const { setSelectedRows } = supplierSlice.actions;
