import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";


// Lấy danh sách cupon
const fetchCupons = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/cupons`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};


// Lấy cupon theo id
const fetchCuponById = async (id) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/cupons/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};


// Tìm kiếm cupon
const searchCupons = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/cupons/search?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};


// Sắp xếp cupon theo id
const sortCuponsById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/cupons/sort?id=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};



// Tạo cupon
const createCupon = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/cupons', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};



// Cập nhật cupon
const updateCupon = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.put(`/cupons/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};


// Xóa cupon
const deleteCupon = async (id) => {
    try {
        const token = getAccessToken();
        const response = await api.delete(`/cupons/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};


// Áp dụng cupon
const applyCupon = async (maKhuyenMai, data) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/cupons/apply/${maKhuyenMai}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const responseData = response.data;
        return responseData;
    } catch (error) {
        throw new Error('failed');
    }
};




const CuponService = {
    fetchCupons,
    fetchCuponById,
    searchCupons,
    sortCuponsById,
    createCupon,
    updateCupon,
    deleteCupon,
    applyCupon
}
export default CuponService;