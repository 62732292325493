import axiosClient from "../commonService/apiConfig";
import { getAccessToken } from "~utils/helper";

const endpoint = "/DonHang";

// Lấy danh sách tất cả đơn hàng
export const getAllOrder = async (params = {}) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(endpoint, {
            params,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Lấy danh sách tất cả đơn hàng theo email
export const getAllOrderByEmail = async (email) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(`${endpoint}/Khac/Email/${email}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Lấy danh sách tất cả đơn hàng theo mã đơn hàng
export const getAllOrderById = async (maDonHang) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(`${endpoint}/Khac/${maDonHang}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response;
    } catch (error) {
        throw new Error('failed');
    }
};


// Lấy thông tin chi tiết đơn hàng
export const getOrderDetails = async (orderId) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(`${endpoint}/Khac/chiTietDonHang/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Cập nhật trạng thái đơn hàng
export const updateOrderStatus = async (orderId, status) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.patch(`${endpoint}/Khac/${orderId}`, {
            trangThai: status
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Tạo đơn hàng
export const createDonHang = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.post(endpoint, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Lấy danh sách đơn hàng theo mã người dùng
export const getAllOrderByUserId = async (userId) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(`${endpoint}/Khac/donHangCuaToi/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Cập nhật trạng thái thanh toán đơn hàng
export const updateTrangThaiThanhToanDonHang = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.post(`${endpoint}/update-trang-thai-thanh-toan`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};


// Gửi thông báo đã đọc
export const sendReadNotification = async (data) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.patch(`TrangThaiDonHang/thongBaoDonHang/${data.maDonHang}/${data.trangThai}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to send read notification');
    }
};


// Lấy tất cả trạng thái đơn theo mã đơn hàng
export const getOrderStatus = async (maDonHang) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.get(`/TrangThaiDonHang/thongBaoDonHang/${maDonHang}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};

// Thêm mã vận đơn
export const themMaVanDon = async (maHoaDon, maVanDon) => {
    try {
        const token = getAccessToken();
        const response = await axiosClient.patch(`${endpoint}/Khac/themMaVanDon`, {
            maHoaDon: maHoaDon,
            maVanDon: maVanDon
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};



const OrderAPI = {
    getAllOrder,
    getAllOrderById,
    getAllOrderByEmail,
    getOrderDetails,
    updateOrderStatus,
    createDonHang,
    updateTrangThaiThanhToanDonHang,
    getAllOrderByUserId,
    sendReadNotification,
    getOrderStatus,
    themMaVanDon
};
export default OrderAPI;

