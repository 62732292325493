import api from "../commonService/apiConfig";
import { getAccessToken } from "~utils/helper";


// Lấy danh sách loại sản phẩm
const fetchCategories = async () => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/LoaiSanPham?sort=maLoaiSanPham,asc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error('failed');
  }
};


// Lấy thông tin loại sản phẩm theo mã loại sản phẩm
const fetchCategoryById = async (id: string) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/LoaiSanPham/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error('failed');
  }
}

const CategoryService = {
  fetchCategories,
  fetchCategoryById
}
export default CategoryService;