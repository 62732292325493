import { Button } from '@mui/material';
import React from 'react';
import ErrorImage from '~components/image/main_icon/load1.gif';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Cập nhật state để hiển thị giao diện dự phòng.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Bạn có thể log lỗi vào một service log lỗi tại đây.
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h1>Oops! Có gì đó sai sai...</h1>
                    <img src={ErrorImage} alt="Thông báo lỗi" />
                    <p>Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng thử lại sau.</p>
                    <Button variant="contained" color="primary" onClick={() => window.location.reload()}>Tải lại trang</Button>
                </div>
            );
        }

        return this.props.children;
    }

}

export default ErrorBoundary;
