import React, { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './ScrollButton.css';

const ScrollButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div className="scroll-button">
            {isVisible && (
                <>
                    <button className="button-scroll1" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <KeyboardArrowUpIcon />
                    </button>
                    <button className="button-scroll2" onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>
                        <KeyboardArrowDownIcon />
                    </button>
                </>
            )}
        </div>
    );
};

export default ScrollButton;