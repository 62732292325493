import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

// Fetch all campaigns
const fetchCampaigns = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get('/SuKien', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch campaigns');
    }
};

// Search campaigns with pagination
const searchCampaigns = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get('/SuKien', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                search: searchTerm,
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to search campaigns');
    }
};

// Sort campaigns by ID
const sortCampaignsById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maSuKien,asc' : 'maSuKien,desc';
        const response = await api.get('/SuKien', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sortOption,
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort campaigns by ID');
    }
};

// Create a new campaign
const createCampaign = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/SuKien', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to create campaign');
    }
};

// Update an existing campaign
const updateCampaign = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/SuKien`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to update campaign');
    }
};

// Delete a campaign
const deleteCampaign = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/SuKien/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Campaign deleted successfully';
    } catch (error) {
        throw new Error('Failed to delete campaign');
    }
};

// Delete multiple campaigns
const deleteMultipleCampaigns = async (ids) => {
    try {
        const token = getAccessToken();
        await api.delete('/SuKien', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { ids },
        });
        return 'Campaigns deleted successfully';
    } catch (error) {
        throw new Error('Failed to delete multiple campaigns');
    }
};

const CampaignsService = {
    fetchCampaigns,
    searchCampaigns,
    sortCampaignsById,
    createCampaign,
    updateCampaign,
    deleteCampaign,
    deleteMultipleCampaigns
};

export default CampaignsService;
