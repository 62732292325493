import { createSlice } from '@reduxjs/toolkit';

export const imageSlice = createSlice({
    name: 'image',
    initialState: {
        imageData: null,
    },
    reducers: {
        setImages: (state, action) => {
            state.imageData = action.payload;
        },
    },
});

export const { setImages } = imageSlice.actions;

// @ts-ignore
export const selectImageData = (state) => state.image.imageData;

export default imageSlice.reducer;
