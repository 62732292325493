import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import store from "./redux/store/index.tsx";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App.tsx";
import Loading from '~components/common/other/loading/Loading';
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ErrorBoundary from '~pages/common/error-page/ErrorBoundary';

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <HelmetProvider>
        <React.StrictMode>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<div><Loading /></div>}>
                        <ErrorBoundary>
                            <I18nextProvider i18n={i18n}>
                                <App />
                            </I18nextProvider>
                        </ErrorBoundary>
                    </Suspense>
                </BrowserRouter>
            </ReduxProvider>
        </React.StrictMode>
    </HelmetProvider>
);