/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '~css/global.css';
import { useScrollToTop } from '~hooks/use-scroll-to-top';
import Router from '~routes/routes.tsx';
import ThemeProvider from './components/theme';
import { fetchCart } from '~redux/slices/shopping/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from "~redux/slices/shopping/categorySlice";
import { fetchBrands } from "~redux/slices/shopping/brandSlice";
import { fetchCampaigns } from "~redux/slices/shopping/campaignSlice";
import { fetchCupons } from "~redux/slices/shopping/cuponSlice";
// import { checkAndSaveImages } from '~components/utils/imageStorage';
import ScrollButton from '~components/common/ScrollButton/ScrollButton';
import { getUserId } from '~components/utils/helper.js';
import VisitorCouterAPI from '~services/commonService/visitor_count_api';
import '~css/App.css';


interface RootState {
    common: {
        isSearchBarOpen: boolean;
        searchKeyword: string;
    };
}



const App: React.FC = () => {
    const dispatch = useDispatch();

    const userId = getUserId();

    useEffect(() => {
        // Lưu ảnh vào vào IndexedDB người dùng
        // checkAndSaveImages();
        // Tăng số lượt truy cập
        VisitorCouterAPI.updateVisitorCountedNumber();

        // Lấy thông tin giỏ hàng của người dùng
        //@ts-ignore
        dispatch(fetchCart(userId));

    }, [dispatch, userId]);



    const searchKeyword = useSelector((state: RootState) => state.common.searchKeyword);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);

    useEffect(() => {
        dispatch(fetchCategories() as any);
        dispatch(fetchBrands() as any);
        dispatch(fetchCampaigns() as any);
        dispatch(fetchCupons() as any);
    }, [dispatch, searchTerm, pageNumber, pageSize]);

    useEffect(() => {
        if (searchKeyword) {
            window.scrollTo(0, 0);
        }
    }, [searchKeyword]);


    useScrollToTop();

    return (
        <ThemeProvider>
            <div className="App">
                <div className='noselect'>
                    <Router />
                    <ScrollButton />
                </div>
            </div>
        </ThemeProvider>
    );
};

export default App;
