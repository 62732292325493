import { format, getTime, formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';

// ----------------------------------------------------------------------

// @ts-ignore
export function fDate(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

// @ts-ignore
export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

// @ts-ignore
export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}



// @ts-ignore
export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}


// @ts-ignore
export function fToNow_VietNam(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
            locale: {
                ...vi,
                formatDistance: (token, count, options) => {
                    const result = {
                        lessThanXSeconds: `ít hơn ${count} giây`,
                        xSeconds: `${count} giây`,
                        halfAMinute: 'nửa phút',
                        lessThanXMinutes: `ít hơn ${count} phút`,
                        xMinutes: `${count} phút`,
                        aboutXHours: `khoảng ${count} giờ`,
                        xHours: `${count} giờ`,
                        xDays: `${count} ngày`,
                        aboutXMonths: `khoảng ${count} tháng`,
                        xMonths: `${count} tháng`,
                        aboutXYears: `khoảng ${count} năm`,
                        xYears: `${count} năm`,
                        overXYears: `hơn ${count} năm`,
                        almostXYears: `gần ${count} năm`,
                        lessThanXSecondsWithSuffix: `ít hơn ${count} giây trước`,
                        xSecondsWithSuffix: `${count} giây trước`,
                        halfAMinuteWithSuffix: 'nửa phút trước',
                        lessThanXMinutesWithSuffix: `ít hơn ${count} phút trước`,
                        xMinutesWithSuffix: `${count} phút trước`,
                        aboutXHoursWithSuffix: `khoảng ${count} giờ trước`,
                        xHoursWithSuffix: `${count} giờ trước`,
                        xDaysWithSuffix: `${count} ngày trước`,
                        aboutXMonthsWithSuffix: `khoảng ${count} tháng trước`,
                        xMonthsWithSuffix: `${count} tháng trước`,
                        aboutXYearsWithSuffix: `khoảng ${count} năm trước`,
                        xYearsWithSuffix: `${count} năm trước`,
                        overXYearsWithSuffix: `hơn ${count} năm trước`,
                        almostXYearsWithSuffix: `gần ${count} năm trước`,
                    };

                    // @ts-ignore
                    if (options.addSuffix) {
                        // @ts-ignore
                        return result[token + 'WithSuffix'];
                    }
                    // @ts-ignore
                    return result[token];
                },
            },
        })
        : '';
}