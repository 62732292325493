import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlogsService from "~services/blogService/blog_api";


  
const initialState = {
  blogs: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedBlogs: [],
  errorMessage: [],
}
export const fetchBlogs = createAsyncThunk(
  "blog/fetchBlogs",
  async () => {
    const response = await BlogsService.fetchBlog();
    return response;
  }
);

export const searchBlogs = createAsyncThunk(
  "blog/searchBlogs",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await BlogsService.searchBlog(searchTerm, pageNumber, pageSize);
    return response;
  }
);
export const sortBlogsById = createAsyncThunk(
  "blog/sortBlogsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await BlogsService.sortBlogById(order, pageNumber, pageSize);
    return response;
  }
);

export const createBlog = createAsyncThunk(
  "blog/createBlog",
  async ({data}) => {
    const response = await BlogsService.createBlog(data);
    return response;
  }
);


export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async ({ id, data }) => {
    const response = await BlogsService.updateBlog(id, data);
    return response;
  }
);

export const deleteBlog = createAsyncThunk(
  "blog/deleteBlog",
  async ({id}) => {
    await BlogsService.deleteBlog(id);
    return id; // Trả về blogId sau khi đã xóa
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedBlogs = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.blogs = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchBlogs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchBlogs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogs = action.payload;
      })
      .addCase(searchBlogs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortBlogsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortBlogsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogs = action.payload;
      })
      .addCase(sortBlogsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createBlog.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBlog.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createBlog.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateBlog.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateBlog.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteBlog.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBlog.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteBlog.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = blogSlice;
export default reducer;

export const { clearSelectedRows } = blogSlice.actions;
export const { setSelectedRowKeys } = blogSlice.actions;
export const { setSelectedRows } = blogSlice.actions;
