import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchPayments = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/PhuongThucThanhToan?sort=maPhuongThucThanhToan,asc` ,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchPayments = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/PhuongThucThanhToan?sort=maPhuongThucThanhToan,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Payments');
    }
};

const sortPaymentsById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maPhuongThucThanhToan,asc' : 'maPhuongThucThanhToan,desc';
        const response = await api.get(`/PhuongThucThanhToan?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Payments by id');
    }
};

const createPayment = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/PhuongThucThanhToan', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Payments failed');
    }
};


const updatePayment = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/PhuongThucThanhToan/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Payments failed');
    }
};

const deletePayment = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/PhuongThucThanhToan/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Payments deleted successfully';
    } catch (error) {
        throw new Error('Deleting Payments failed');
    }
};

const PaymentsService = {
    fetchPayments,
    searchPayments,
    sortPaymentsById,
    createPayment,
    updatePayment,
    deletePayment
}
export default PaymentsService;