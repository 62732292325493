import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";


// Lấy số người đã truy cập trang
const getVisitorCountedNumber = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/vistCounter`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

// Cập nhật số người đã truy cập trang
const updateVisitorCountedNumber = async () => {
    try {
        const token = getAccessToken();
        const response = await api.post(`/vistCounter`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        return 0;
    }
};


const VisitorCounterService = {
    getVisitorCountedNumber,
    updateVisitorCountedNumber,
}
export default VisitorCounterService;