import { getAccessToken, getUserId } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchBlog = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const getPostById = async (id) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error('Error fetching post:', error.response || error.message);
        throw new Error('Failed to fetch post');
    }
};

// const getLikedPostByUserId = async (userId) => {
//     try {
//         const token = getAccessToken();
//         const response = await api.get(`/Blog/liked/${userId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         const data = response.data;
//         return data;
//     } catch (error) {
//         console.error('Error fetching post:', error.response || error.message);
//         throw new Error('Failed to fetch post');
//     }
// };

const getPostByCategoryId = async (id) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/ChuDe/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = response.data;
        return data;
    } catch (error) {
        console.error('Error fetching post:', error.response || error.message);
        throw new Error('Failed to fetch post');
    }
};



const searchBlog = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog?sort=maBlog,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Blog');
    }
};

const sortBlogById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maBlog,asc' : 'maBlog,desc';
        const response = await api.get(`/Blog?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Blog by id');
    }
};


const createBlog = async (data) => {
    try {
        const token = getAccessToken();
        const tacGiaId = getUserId(); // Ensure this function correctly fetches user ID
        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('blogCategoryId', data.blogCategoryId);
        formData.append('anhMinhHoa', data.anhMinhHoa);
        formData.append('contentFile', data.contentFile);
        formData.append('tacGiaId', tacGiaId);
        formData.append('tags', data.tags);
        formData.append('moTaNgan', data.moTaNgan);
        formData.append('status', data.status);
        formData.append('view', 100);

        const response = await api.post('/Blog', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Creating blog failed:', error);
        throw new Error('Creating blog failed: ' + error.response?.data?.message || error.message);
    }
};



const upToFireBase = async (data) => {
    try {
        const token = getAccessToken();
        const tacGiaId = getUserId();
        const configData = { ...data, tacGiaId }
        const response = await api.post('/FireBase', configData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Blog failed');
    }
};


const updateBlog = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/Blog/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Blog failed');
    }
};


// Lấy danh sách blog đã thích của người dùng
const getLikedBlogList = async (userId) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/getLikedBlogList/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Blog failed');
    }
};


// Thêm vào danh sách đã thích
const addToFavoriteBlog = async (blogId, userId) => {
    try {
        const token = getAccessToken();
        const response = await api.put(`/Blog/updateLikedBlogList/${blogId}/${userId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Blog failed');
    }
};



const deleteBlog = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/Blog/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Blog deleted successfully';
    } catch (error) {
        throw new Error('Deleting Blog failed');
    }
};

const BlogService = {
    fetchBlog,
    getPostById,
    // getLikedPostByUserId,
    getPostByCategoryId,
    upToFireBase,
    searchBlog,
    sortBlogById,
    createBlog,
    updateBlog,
    deleteBlog,
    addToFavoriteBlog,
    getLikedBlogList
}
export default BlogService;