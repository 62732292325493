import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

// Lấy danh sách tòan bộ Chi Tiêu
const fetchChiTieus = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/ChiTieu`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};



// Tạo chi tiêu mới
const createChiTieu = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/ChiTieu', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating ChiTieus failed');
    }
};



// Cập nhật thông tin chi tiêu
const updateChiTieu = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/ChiTieu/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change ChiTieus failed');
    }
};


// Xóa chi tiêu
const deleteChiTieu = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/ChiTieu/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'ChiTieus deleted successfully';
    } catch (error) {
        throw new Error('Deleting ChiTieus failed');
    }
};



const ChiTieusService = {
    fetchChiTieus,
    createChiTieu,
    updateChiTieu,
    deleteChiTieu
}
export default ChiTieusService;