import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSearchBarOpen: false,
    searchKeyword: "",
    language: "vn",
    newMessagesCount: 0,
    isFilter: false,
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        toggleSearchBar: (state) => {
            state.isSearchBarOpen = !state.isSearchBarOpen;
        },
        setSearchKeyword: (state, action) => {
            state.searchKeyword = action.payload.searchKeyword;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        error: (state, action) => {
            throw new Error(`Unknown action: ${action.type}`);
        },
        newMessagesCount: (state, action) => {
            state.newMessagesCount = action.payload;
        },
        isFilter: (state, action) => {
            state.isFilter = action.payload;
        },
        resetFilter: (state) => {
            state.isFilter = false;
        }

    }
});

export const { toggleSearchBar, error, setSearchKeyword, setLanguage, newMessagesCount, isFilter, resetFilter } = commonSlice.actions;

export default commonSlice.reducer;