import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const url = "/NhaCungCap";

const fetchSuppliers = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`${url}?sort=maNhaCungCap,asc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        const data = response.data;
        return data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchSuppliers = async (searchTerm: any, pageNumber: any, pageSize: any) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`${url}?sort=maNhaCungCap,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to search Suppliers');
    }
};

const sortSuppliersById = async (order: string, pageNumber: any, pageSize: any) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'maNhaCungCap,asc' : 'maNhaCungCap,desc';
        const response = await api.get(`${url}?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort Suppliers by id');
    }
};

const createSupplier = async (data: any) => {
    try {
        const token = getAccessToken();
        const response = await api.post(`${url}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const createdProduct = response.data;
        return createdProduct;
    } catch (error) {
        throw new Error('Creating Suppliers failed');
    }
};


const updateSupplier = async (id: any, data: any) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`${url}/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change Suppliers failed');
    }
};

const deleteSupplier = async (id: any) => {
    try {
        const token = getAccessToken();
        await api.delete(`${url}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'Suppliers deleted successfully';
    } catch (error) {
        throw new Error('Deleting Suppliers failed');
    }
};

// Hàm lấy thông tin Nhà Cung Cấp theo mã Nhà Cung Cấp
const getNhaCungCapById = async (maNhaCungCap: any) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`${url}/maNhaCungCap`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Không thể lấy danh sách nhà cung cấp');
    }
};

const SuppliersService = {
    fetchSuppliers,
    searchSuppliers,
    sortSuppliersById,
    createSupplier,
    updateSupplier,
    deleteSupplier,
    getNhaCungCapById
}
export default SuppliersService;