import CryptoJS from 'crypto-js';

// Khóa bí mật được lấy từ biến môi trường
const secretKey =  process.env.REACT_APP_CRYPTOJS_SECRET_KEY; 

// Hàm mã hóa dữ liệu
export const encryptData = (data) => {
  // Chuyển đổi dữ liệu thành chuỗi JSON và mã hóa sử dụng khóa bí mật
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return ciphertext; // Trả về dữ liệu đã mã hóa
};

// Hàm giải mã dữ liệu
export const decryptData = (ciphertext) => {
  // Giải mã dữ liệu sử dụng khóa bí mật
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  // Chuyển đổi dữ liệu giải mã thành chuỗi UTF8 và phân tích JSON
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData; // Trả về dữ liệu đã giải mã
};
