import React from "react";
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { bgBlur } from '~components/theme/css.tsx';
import Iconify from '~components/image/iconify/iconify.tsx';
import Searchbar from '../component/searchbar.tsx';
import { NAV, HEADER } from '../component/config-layout.tsx';
import AccountPopover from '../component/account-popover.tsx';
import LanguagePopover from '../component/language-popover.tsx';
import NotificationsPopover from '../component/notifications-popover';
import CartPopover from '../component/cart-popover.tsx';

// @ts-ignore
const Header = ({ onOpenNav, menuControl }) => {
    const theme = useTheme();

    // @ts-ignore
    const lgUp = useResponsive('up', 'lg');

    const renderContent = (
        <>
            {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
                    <
                        // @ts-ignore
                        Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

            {/*// @ts-ignore*/}
            <Searchbar sx={{ flexGrow: 1 }} />

            {/*<Box sx={{flexGrow: 1}}/>*/}

            <div style={{ marginLeft: "auto" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <LanguagePopover />
                    <NotificationsPopover menuControl={menuControl} />
                    {/* //Ngăn hành động click vào các ô khác làm thay đổi trạng thái của giỏ hàng */}
                    <div onClick={(e) => e.stopPropagation()}>
                        {/*// @ts-ignore*/}
                        <CartPopover />
                        {/* {menuControl && <CartPopover />} */}
                    </div>
                    <AccountPopover menuControl={menuControl} />
                </Stack>
            </div>
        </>
    );

    return (
        <AppBar
            // @ts-ignore
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: 1000,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: HEADER.H_DESKTOP,
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 3 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};


export default Header;