import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";

const fetchBlogCategory = async () => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/DanhMuc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};

const getCategoryById = async (id) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/DanhMuc/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('failed');
    }
};

const searchBlogCategory = async (searchTerm, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const response = await api.get(`/Blog/DanhMuc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                search: searchTerm,
                sort: 'categoryName,asc',
                pageNumber: pageNumber - 1,  // Backend pagination is 0-based
                pageSize: pageSize,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to search BlogCategory');
    }
};

const sortBlogCategoryById = async (order, pageNumber, pageSize) => {
    try {
        const token = getAccessToken();
        const sortOption = order === 'asc' ? 'id/categoryName,asc' : 'id/categoryName,desc';
        const response = await api.get(`/Blog/DanhMuc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                sort: sortOption,
                pageNumber: pageNumber - 1,  // Backend pagination is 0-based
                pageSize: pageSize,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to sort BlogCategory by id');
    }
};


const createBlogCategory = async (data) => {
    try {
        const token = getAccessToken();
        const response = await api.post('/Blog/DanhMuc', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Creating BlogCategory failed');
    }
};

const updateBlogCategory = async (id, data) => {
    try {
        const token = getAccessToken();
        const response = await api.patch(`/Blog/DanhMuc/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Change BlogCategory failed');
    }
};

const deleteBlogCategory = async (id) => {
    try {
        const token = getAccessToken();
        await api.delete(`/Blog/DanhMuc/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return 'BlogCategory deleted successfully';
    } catch (error) {
        throw new Error('Deleting BlogCategory failed');
    }
};

const BlogCategoryService = {
    fetchBlogCategory,
    getCategoryById,
    searchBlogCategory,
    sortBlogCategoryById,
    createBlogCategory,
    updateBlogCategory,
    deleteBlogCategory
};
export default BlogCategoryService;
