import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PaymentsService from "~services/shoppingService/payment_api";


  
const initialState = {
  payments: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedPayments: [],
  errorMessage: [],
}
export const fetchPayments = createAsyncThunk(
  "payment/fetchPayments",
  async () => {
    const response = await PaymentsService.fetchPayments();
    return response;
  }
);

export const searchPayments = createAsyncThunk(
  "payment/searchPayments",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await PaymentsService.searchPayments(searchTerm, pageNumber, pageSize);
    return response;
  }
);
export const sortPaymentsById = createAsyncThunk(
  "payment/sortPaymentsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await PaymentsService.sortPaymentsById(order, pageNumber, pageSize);
    return response;
  }
);

export const createPayment = createAsyncThunk(
  "payment/createPayment",
  async ({data}) => {
    const response = await PaymentsService.createPayment(data);
    return response;
  }
);


export const updatePayment = createAsyncThunk(
  "payment/updatePayment",
  async ({ id, data }) => {
    const response = await PaymentsService.updatePayment(id, data);
    return response;
  }
);

export const deletePayment = createAsyncThunk(
  "payment/deletePayment",
  async ({id}) => {
    await PaymentsService.deletePayment(id);
    return id; // Trả về paymentId sau khi đã xóa
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedPayments = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.payments = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payments = action.payload;
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchPayments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchPayments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payments = action.payload;
      })
      .addCase(searchPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortPaymentsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortPaymentsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payments = action.payload;
      })
      .addCase(sortPaymentsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createPayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPayment.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updatePayment.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updatePayment.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deletePayment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePayment.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deletePayment.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = paymentSlice;
export default reducer;

export const { clearSelectedRows } = paymentSlice.actions;
export const { setSelectedRowKeys } = paymentSlice.actions;
export const { setSelectedRows } = paymentSlice.actions;
