import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PromotionsService from "~services/shoppingService/promotion_api";



const initialState = {
    promotions: [],
    isLoading: false,
    selectedRowKeys: [],
    selectedPromotions: [],
    errorMessage: [],
}


export const getPromotionProducts = createAsyncThunk(
    "promotionProduct/getPromotionProducts",
    async () => {
        const response = await PromotionsService.getPromotionProducts();
        return response;
    }
);

export const createPromotionProduct = createAsyncThunk(
    "promotionProduct/createPromotionProduct",
    async () => {
        const response = await PromotionsService.createPromotionProduct();
        return response;
    }
);

export const updatePromotionProduct = createAsyncThunk(
    "promotionProduct/updatePromotionProduct",
    async () => {
        const response = await PromotionsService.updatePromotionProduct();
        return response;
    }
);

export const deletePromotionProduct = createAsyncThunk(
    "promotionProduct/deletePromotionProduct",
    async () => {
        const response = await PromotionsService.deletePromotionProduct();
        return response;
    }
);


export const getPromotions = createAsyncThunk(
    "promotion/getPromotions",
    async () => {
        const response = await PromotionsService.getPromotions();
        return response;
    }
);


export const createPromotion = createAsyncThunk(
    "promotion/createPromotion",
    async ({ data }) => {
        const response = await PromotionsService.createPromotion(data);
        return response;
    }
);


export const updatePromotion = createAsyncThunk(
    "promotion/updatePromotion",
    async ({ id, data }) => {
        const response = await PromotionsService.updatePromotion(id, data);
        return response;
    }
);

export const deletePromotion = createAsyncThunk(
    "promotion/deletePromotion",
    async ({ id }) => {
        await PromotionsService.deletePromotion(id);
        return id; // Trả về promotionId sau khi đã xóa
    }
);

const promotionSlice = createSlice({
    name: "promotion",
    initialState,
    reducers: {
        setSelectedRowKeys(state, action) {
            state.selectedRowKeys = action.payload;
        },
        setSelectedRows(state, action) {
            state.selectedPromotions = action.payload;
        },
        clearSelectedRows(state) {
            state.selectedRows = [];
            state.selectedRowKeys = [];
        },
        logout: (state) => {
            state.promotions = null;
            state.errorMessage = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromotions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPromotions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promotions = action.payload;
            })
            .addCase(getPromotions.rejected, (state, action) => {
                state.status = 'failed';
                state.errorMessage = action.payload;
            })
            .addCase(createPromotion.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPromotion.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createPromotion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updatePromotion.pending, (state) => {
                state.isLoading = true;
                state.status = 'loading';
            })
            .addCase(updatePromotion.fulfilled, (state) => {
                state.isLoading = false;
                state.status = 'succeeded';
            })
            .addCase(updatePromotion.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deletePromotion.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePromotion.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(deletePromotion.rejected, (state, error) => {
                state.status = 'failed';
                state.errorMessage = error;
            })
            
            .addCase(deletePromotionProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePromotionProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promotions = action.payload;
            })
            .addCase(deletePromotionProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.errorMessage = action.payload;
            })
            
            .addCase(updatePromotionProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePromotionProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promotions = action.payload;
            })
            .addCase(updatePromotionProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.errorMessage = action.payload;
            })

            .addCase(createPromotionProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPromotionProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promotions = action.payload;
            })
            .addCase(createPromotionProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.errorMessage = action.payload;
            })

            .addCase(getPromotionProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPromotionProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.promotions = action.payload;
            })
            .addCase(getPromotionProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.errorMessage = action.payload;
            })
    },
});
const { reducer } = promotionSlice;
export default reducer;

export const { clearSelectedRows } = promotionSlice.actions;
export const { setSelectedRowKeys } = promotionSlice.actions;
export const { setSelectedRows } = promotionSlice.actions;
