import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BlogCategorysService from "~services/blogService/blogCategory_api";


//----------------------------------------------  
const initialState = {
  blogCategorys: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedBlogCategorys: [],
  errorMessage: [],
}
export const fetchBlogCategorys = createAsyncThunk(
  "blogCategory/fetchBlogCategorys",
  async () => {
    const response = await BlogCategorysService.fetchBlogCategory();
    return response;
  }
);

export const searchBlogCategorys = createAsyncThunk(
  "blogCategory/searchBlogCategorys",
  async () => {
    const response = await BlogCategorysService.searchBlogCategory();
    return response;
  }
);

export const sortBlogCategorysById = createAsyncThunk(
  "blogCategory/sortBlogCategorysById",
  async () => {
    const response = await BlogCategorysService.sortBlogCategoryById();
    return response;
  }
);

export const createBlogCategory = createAsyncThunk(
  "blogCategory/createBlogCategory",
  async ({data}) => {
    const response = await BlogCategorysService.createBlogCategory(data);
    return response;
  }
);


export const updateBlogCategory = createAsyncThunk(
  "blogCategory/updateBlogCategory",
  async ({ id, data }) => {
    const response = await BlogCategorysService.updateBlogCategory(id, data);
    return response;
  }
);

export const deleteBlogCategory = createAsyncThunk(
  "blogCategory/deleteBlogCategory",
  async ({id}) => {
    await BlogCategorysService.deleteBlogCategory(id);
    return id; // Trả về blogCategoryId sau khi đã xóa
  }
);

const blogCategorySlice = createSlice({
  name: "blogCategory",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedBlogCategorys = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.blogCategorys = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogCategorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogCategorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogCategorys = action.payload;
      })
      .addCase(fetchBlogCategorys.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchBlogCategorys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchBlogCategorys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogCategorys = action.payload;
      })
      .addCase(searchBlogCategorys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortBlogCategorysById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortBlogCategorysById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.blogCategorys = action.payload;
      })
      .addCase(sortBlogCategorysById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createBlogCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBlogCategory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createBlogCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateBlogCategory.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateBlogCategory.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateBlogCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteBlogCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBlogCategory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteBlogCategory.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = blogCategorySlice;
export default reducer;

export const { clearSelectedRows } = blogCategorySlice.actions;
export const { setSelectedRowKeys } = blogCategorySlice.actions;
export const { setSelectedRows } = blogCategorySlice.actions;
