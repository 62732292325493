import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Nav from './nav';
import Main from '../component/main';
import Header from '../component/header';
import Footer from "~layouts/component/Footer";
import { useResponsive } from '~hooks/use-responsive.tsx';
import { HEADER } from '../component/config-layout';
import Chat from "~components/firebase/chat-app/chat_user";
import { getUserId } from "~components/utils/helper";

const SPACING = 8;


// @ts-ignore
export default function CustomLayout({ children, isLogIn }) {
    const [openNav, setOpenNav] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [menuControl, setMenuControl] = useState(isLogIn);

    // @ts-ignore
    const lgUp = useResponsive('up', 'lg');

    const userId = getUserId();

    return (
        <>
            <Header menuControl={menuControl} onOpenNav={() => setOpenNav(true)} />

            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    justifyContent: 'space-between',
                }}
            >
                <Nav menuControl={menuControl} openNav={openNav} onCloseNav={() => setOpenNav(false)} />

                {/*// @ts-ignore*/}
                <Main
                    sx={{
                        // backgroundColor: 'red',
                        pt: `${HEADER.H_MOBILE + SPACING}px`, // chỉ đặt padding trên
                        pb: 0, // đặt padding dưới = 0
                        pl: 0, // đặt padding trái = 0
                        pr: 0, // đặt padding phải = 0
                        ...(lgUp && {
                            pt: `${HEADER.H_DESKTOP + SPACING}px`, // chỉ đặt padding trên
                            pb: 0, // đặt padding dưới = 0
                            pl: 0, // đặt padding trái = 0
                            pr: 0, // đặt padding phải = 0
                        }),
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {children}
                </Main>

                {/* Modul Chat */}
                <Chat userId={userId} />

            </Box>

            <Footer />
        </>
    );
}

CustomLayout.propTypes = {
    children: PropTypes.node,
};