/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Badge, Typography } from '@mui/material'
import { useResponsive } from '~hooks/use-responsive.tsx';
import { bgBlur } from '~components/theme/css.tsx';
import Iconify from '~components/image/iconify/iconify.tsx';
// import Searchbar from '../component/searchbar.tsx';
import { NAV, HEADER } from '../component/config-layout.tsx';
import AccountPopover from '../component/account-popover.tsx';
// import LanguagePopover from '../component/language-popover.tsx';
import NotificationsPopover from '../component/notifications-popover';
import CartPopover from '../component/cart-popover.tsx';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import OrderAPI from "~services/shoppingService/order_api"
import { useSelector, useDispatch } from "react-redux";
import { getAllProduct } from '~redux/slices/shopping/productsSlice';



//--------------------------Header--------------------------//
// @ts-ignore
const Header = ({ onOpenNav, menuControl }) => {
    // @ts-ignore
    const newChatNotificationsCount = useSelector((state) => state.common.newMessagesCount);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("MaDonHang");
    const [filters, setFilters] = useState({
        pageSize: 500,
        pageNumber: 1,
    });
    const [newOrderNotificationsCount, setNewOrderNotificationsCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5000);

    const theme = useTheme();
    const navigate = useNavigate();

    // @ts-ignore
    const lgUp = useResponsive('up', 'up');

    const dispatch = useDispatch();

    useEffect(() => {
        //@ts-ignore
        dispatch(getAllProduct({ pageNumber, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pageNumber, pageSize]);


    const soLuongSanPhamSapHet = useSelector((state) => {
        //@ts-ignore
        const products = state.product?.products?.content || [];
        // Lọc các sản phẩm có số lượng nhỏ hơn 2
        //@ts-ignore
        const sanPhamSapHet = products.filter((sanPham) => sanPham.soLuongSanPham < 2);
        // Đếm số lượng sản phẩm sắp hết
        return sanPhamSapHet.length;
    });


    const getData = async () => {
        try {
            const response = await OrderAPI.getAllOrder({
                ...filters,
                sort: `${orderBy},${order}`,
            });
            const donHangChuaDuyet = response.content.filter((order: { trangThaiMoiNhat: string; }) => order.trangThaiMoiNhat === "ChoDuyet").length;
            setNewOrderNotificationsCount(donHangChuaDuyet)

        } catch (error) {
            console.log(`error`, error);
        }
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = (
        <>
            {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{}}>
                    <
                        // @ts-ignore
                        Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

            <Container sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                    onClick={() => navigate("/ceo")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        {/*// @ts-ignore*/}
                        <Iconify icon="tabler:home-cog" />
                    </IconButton>
                </Box>

                <Box
                    onClick={() => navigate("/ceo/shopping-manager")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        {/*// @ts-ignore*/}
                        <Iconify icon="bxs:shopping-bags" />
                    </IconButton>
                </Box>

                <Box
                    onClick={() => navigate("/ceo/blog-manager")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        {/*// @ts-ignore*/}
                        <Iconify icon="fa6-solid:blog" />
                    </IconButton>
                </Box>

                <div style={{ marginLeft: "200px" }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                            onClick={() => navigate("/seller/order-manager-rutgon")}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton>
                                <Badge color="error" badgeContent={newOrderNotificationsCount}>
                                    {/*// @ts-ignore*/}
                                    <Iconify icon="icon-park-outline:order" />
                                </Badge>
                                <Typography style={{ marginLeft: "10px" }} variant="body1">Đơn hàng</Typography>
                            </IconButton>
                        </Box>

                        <Box
                            onClick={() => navigate("/ceo/sap-het-hang")}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton>
                                <Badge color="error" badgeContent={soLuongSanPhamSapHet}>
                                    {/*// @ts-ignore*/}
                                    <Iconify icon="ic:baseline-rotate-90-degrees-ccw" />
                                </Badge>
                                <Typography style={{ marginLeft: "10px" }} variant="body1">Sắp hết hàng</Typography>
                            </IconButton>
                        </Box>

                        <Box
                            onClick={() => navigate("/seller/chat")}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton>
                                <Badge color="error" badgeContent={newChatNotificationsCount}>
                                    {/*// @ts-ignore*/}
                                    <Iconify icon="token:chat" />
                                </Badge>
                                <Typography style={{ marginLeft: "10px" }} variant="body1">Tin nhắn</Typography>
                            </IconButton>
                        </Box>
                    </Stack>
                </div>

            </Container>

            <div style={{ marginLeft: "auto" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <LanguagePopover /> */}
                    <NotificationsPopover menuControl={menuControl} />
                    {/* //Ngăn hành động click vào các ô khác làm thay đổi trạng thái của giỏ hàng */}
                    <div onClick={(e) => e.stopPropagation()}>
                        {/*// @ts-ignore*/}
                        {menuControl && <CartPopover />}
                    </div>
                    <AccountPopover menuControl={menuControl} />
                </Stack>
            </div>
        </>
    );

    return (
        // Đổi AppBar thành div để không bị che bởi Nav
        <AppBar
            // @ts-ignore
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: 1000,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: HEADER.H_DESKTOP,
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};


export default Header;