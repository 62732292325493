/* eslint-disable jsx-a11y/alt-text */
// import SvgColor from '~components/theme/svg-color/svg-color.tsx';


// @ts-ignore
const icon = (name) => (
    // @ts-ignore
    <img src={process.env.PUBLIC_URL + `/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
    {
        title: 'Bán Hàng',
        path: '/seller',
        icon: icon('ic_home'),
    },
    {
        title: ' Quản lý Đơn hàng',
        path: '/seller/order-manager-rutgon',
        icon: icon('order'),
    },
    {
        title: 'Quản lý Tin nhắn',
        path: '/seller/chat',
        icon: icon('tinnhan'),
    },
    {
        title: 'Shopee',
        path: 'https://banhang.shopee.vn/',
        icon: icon('shopee'),
        target: '_blank',
    },
    {
        title: 'Tiki',
        path: 'https://sellercenter.tiki.vn/new/',
        icon: icon('tiki'),
        target: '_blank',
    },
    {
        title: 'Lazada',
        path: 'https://sellercenter.lazada.vn',
        icon: icon('lazada'),
        target: '_blank',
    },
    {
        title: 'Giao hàng tiết kiệm',
        path: 'https://khachhang.giaohangtietkiem.vn/web/',
        icon: icon('ghtk'),
        target: '_blank',
    },
    {
        title: 'Viettel Post',
        path: 'https://viettelpost.vn/dashboard',
        icon: icon('viettelpost'),
        target: '_blank',
    },
    {
        title: 'Facebook',
        path: 'https://www.facebook.com/nostones.vn',
        icon: icon('facebook'),
        target: '_blank',
    },
    {
        title: 'Instagram',
        path: 'https://www.instagram.com/nostones.vn/',
        icon: icon('instagram'),
        target: '_blank',
    },
    {
        title: 'Zalo',
        path: 'https://zalo.me/84971520865',
        icon: icon('zalo'),
        target: '_blank',
    },
    {
        title: 'Titok',
        path: 'https://www.tiktok.com/@nostones.vn',
        icon: icon('tiktok'),
        target: '_blank',
    },
    {
        title: 'Youtube',
        path: 'https://www.youtube.com/@nostones_vn',
        icon: icon('youtube'),
        target: '_blank',
    },
];

export default navConfig;
