import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Nav from './nav.tsx';
import Main from '../component/main.tsx';
import Header from './header.tsx';


//--------------------------SellerLayout--------------------------//
// @ts-ignore
export default function SellerLayout({ children, isLogIn, isSeller }) {
  const [openNav, setOpenNav] = useState(false);


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [menuControl, setMenuControl] = useState(isLogIn);

  return (
    <>
      <Header menuControl={menuControl} onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        {/*// @ts-ignore */}
        <Main>
          {children}
        </Main>
      </Box>
      {/* <Footer /> */}
    </>
  );
}

SellerLayout.propTypes = {
  children: PropTypes.node,
};