import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ShippingsService from "~services/shoppingService/shipping_api";


  
const initialState = {
  shippings: [],
  isLoading: false,
  selectedRowKeys: [],
  selectedShippings: [],
  errorMessage: [],
}
export const fetchShippings = createAsyncThunk(
  "shipping/fetchShippings",
  async () => {
    const response = await ShippingsService.fetchShippings();
    return response;
  }
);

export const searchShippings = createAsyncThunk(
  "shipping/searchShippings",
  async ({ searchTerm, pageNumber, pageSize }) => {
    const response = await ShippingsService.searchShippings(searchTerm, pageNumber, pageSize);
    return response;
  }
);
export const sortShippingsById = createAsyncThunk(
  "shipping/sortShippingsById",
  async ({ order, pageNumber, pageSize }) => {
    const response = await ShippingsService.sortShippingsById(order, pageNumber, pageSize);
    return response;
  }
);

export const createShipping = createAsyncThunk(
  "shipping/createShipping",
  async ({data}) => {
    const response = await ShippingsService.createShipping(data);
    return response;
  }
);


export const updateShipping = createAsyncThunk(
  "shipping/updateShipping",
  async ({ id, data }) => {
    const response = await ShippingsService.updateShipping(id, data);
    return response;
  }
);

export const deleteShipping = createAsyncThunk(
  "shipping/deleteShipping",
  async ({id}) => {
    await ShippingsService.deleteShipping(id);
    return id; // Trả về shippingId sau khi đã xóa
  }
);

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSelectedRows(state, action) {
      state.selectedShippings = action.payload;
    },
    clearSelectedRows(state) {
      state.selectedRows = [];
      state.selectedRowKeys = [];
    },
    logout: (state) => {
      state.shippings = null;
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchShippings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shippings = action.payload;
      })
      .addCase(fetchShippings.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      })
      .addCase(searchShippings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchShippings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shippings = action.payload;
      })
      .addCase(searchShippings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(sortShippingsById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sortShippingsById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.shippings = action.payload;
      })
      .addCase(sortShippingsById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createShipping.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createShipping.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createShipping.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateShipping.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(updateShipping.fulfilled, (state) => {
        state.isLoading = false;
        state.status = 'succeeded';
      })
      .addCase(updateShipping.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteShipping.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteShipping.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteShipping.rejected, (state, error) => {
        state.status = 'failed';
        state.errorMessage = error;
      })
  },
});
const { reducer } = shippingSlice;
export default reducer;

export const { clearSelectedRows } = shippingSlice.actions;
export const { setSelectedRowKeys } = shippingSlice.actions;
export const { setSelectedRows } = shippingSlice.actions;
