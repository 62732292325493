// Import hàm giải mã từ thư viện hỗ trợ xác thực
import { decryptData } from '~hooks/auth/cryptoHelpers';

// Hook sử dụng để xác thực người dùng
const useAuth = () => {
    // Lấy thông tin người dùng đã mã hóa từ localStorage hoặc sessionStorage
    const encryptedRole = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser') || null;
    // Giải mã thông tin người dùng nếu có
    const decryptedRole = encryptedRole ? decryptData(encryptedRole) : null;
    // userInfo sẽ là thông tin người dùng sau khi giải mã, hoặc chuỗi rỗng nếu không có thông tin
    const userInfo = decryptedRole ?? "";
    // Kiểm tra trạng thái đăng nhập dựa vào trạng thái 'Active' của người dùng
    const isLogIn = decryptedRole?.status === 'Active';
    // Kiểm tra vai trò của người dùng có phải là CEO không
    const isCEO = decryptedRole?.role === 'CEO';

    // Kiểm tra vai trò của người dùng có phải là Seller không
    const isSeller = decryptedRole?.role === 'Seller';

    // Trả về các trạng thái và thông tin người dùng
    return { isLogIn, isCEO, isSeller, userInfo };
};

// Xuất hook để sử dụng ở các thành phần khác
export default useAuth;
