import React, { useState, useEffect, useRef } from 'react';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { realtime_db } from '../firebaseConfig';
import { ref, set, push, onValue, update } from 'firebase/database';
import { Input, Button, List, Avatar, Space, message as antdMessage, Badge } from 'antd';
import { CloseOutlined, MessageOutlined, SendOutlined } from '@ant-design/icons';
import FileInput from './FileInput';
import './chat.css';
import { getRole, getUserName } from '~utils/helper';

const Chat = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState(0);
  const chatListRef = useRef(null);

  const [guestUId, setGuestUId] = useState(sessionStorage.getItem('guestUId') || '');

  const randomId = Math.floor(Math.random() * 9000000) + 1000000;
  useEffect(() => {
    if (!userId) {
      setGuestUId(randomId);
      sessionStorage.setItem('isGuest', true);
      sessionStorage.setItem('guestUId', randomId);
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    const messagesRef = ref(realtime_db, 'messages');
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];

      const isGuest = sessionStorage.getItem('isGuest') === 'true';
      const currentUserUId = isGuest ? sessionStorage.getItem('guestUId') : userId;

      const filteredMessages = messagesList.filter(msg => (msg.role === 'Admin' && msg.userId === currentUserUId) || (msg.role !== 'Admin' && msg.userId === currentUserUId));

      // Kiểm tra nếu không có tin nhắn nào, hiển thị tin nhắn mặc định
      if (filteredMessages.length === 0) {
        filteredMessages.push({
          id: 'defaultMessage',
          username: 'Admin',
          role: 'Admin',
          text: 'Chào bạn!, chúng tôi có thể giúp gì bạn hôm nay',
          timestamp: new Date().toISOString(),
          isReaded: true
        });
      }

      setMessages(filteredMessages);

      const unreadCount = filteredMessages.filter(msg => msg.role === 'Admin' && !msg.isReaded).length;
      setUnreadCounts(unreadCount);

      if (chatListRef.current) {
        chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      }
    });
  }, [userId, guestUId]);


  useEffect(() => {
    if (imageUrl) {
      handleSendMessage();
    }
    // eslint-disable-next-line
  }, [imageUrl]);

  const handleSendMessage = async () => {
    markMessagesAsRead();
    setUnreadCounts(0);
    const savedGuestUId = sessionStorage.getItem('guestUId');

    if (newMessage.trim() || imageUrl) {
      const messageData = {
        title: userId ? `${userId} to Admin` : `${guestUId} to Admin`,
        username: getUserName(),
        role: getRole(),
        userId: userId ? userId : savedGuestUId,
        text: newMessage.trim() ? newMessage : ' ',
        image: imageUrl,
        timestamp: new Date().toISOString(),
        isReaded: false
      };

      setNewMessage('');
      setImage(null);
      setImageUrl('');

      if (chatListRef.current) {
        setTimeout(() => {
          chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
        }, 0);
      }

      try {
        const messagesRef = ref(realtime_db, 'messages');
        const newMessageRef = push(messagesRef);
        await set(newMessageRef, messageData);
      } catch (error) {
        console.error('Error sending message: ', error);
        if (!userId) {
          const unsentMessages = JSON.parse(sessionStorage.getItem('unsentMessages')) || [];
          unsentMessages.push(messageData);
          sessionStorage.setItem('unsentMessages', JSON.stringify(unsentMessages));
        }
      }
    } else {
      antdMessage.warning('Bạn cần nhập tin nhắn hoặc chọn ảnh để gửi.');
    }
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleImageUpload(file);
    }
  };

  const handleImageUpload = async (file) => {
    if (!file.type.startsWith('image/')) {
      antdMessage.error('Chỉ được phép tải lên tệp hình ảnh.');
      return false;
    }

    setImage(file);
    const url = await uploadImage(file);
    setImageUrl(url);
    return false;
  };

  const uploadImage = async (file) => {
    const storage = getStorage();
    const imageRef = storageRef(storage, `chat-images/${file.name}`);
    await uploadBytes(imageRef, file);
    const url = await getDownloadURL(imageRef);
    return url;
  };


  const handleOpenChat = () => {
    setOpen(true);
    markMessagesAsRead();
  };

  const handleCloseChat = () => {
    setOpen(false);
  };

  const markMessagesAsRead = () => {
    const updates = {};
    messages
      .filter(msg => msg.role === 'Admin' && !msg.isReaded && msg.userId === userId)
      .forEach(msg => {
        updates[`/messages/${msg.id}/isReaded`] = true;
      });

    if (Object.keys(updates).length > 0) {
      update(ref(realtime_db), updates);
    }

    setUnreadCounts(0);
  };

  return (
    <div className="chat-container">
      <div className="badge-container">
        <Badge count={unreadCounts} size="small" offset={[0, -25]} style={{ visibility: open ? 'hidden' : 'visible' }}>
          <Button
            style={{ backgroundColor: '#57C8CC', visibility: open ? 'hidden' : 'visible' }}
            className="chat-toggle-button"
            type="primary"
            shape="circle"
            icon={<MessageOutlined />}
            onClick={handleOpenChat}
          />
        </Badge>
      </div>

      {
        open && (
          <div className="chat-box">
            <div className="chat-header">
              <Avatar src={`${process.env.PUBLIC_URL + "/logo23.png"}`} size="large" />
              <span className="chat-title">Nostones</span>
              <Button
                className="chat-close-button"
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCloseChat}
              />
            </div>

            <div className="chat-list" ref={chatListRef}>
              <List
                itemLayout="vertical"
                dataSource={messages}
                renderItem={(msg) => (
                  <List.Item
                    className={`chat-message ${msg.role === 'Admin' ? 'admin-message' : 'user-message'}`}
                  >
                    <div className={`message-content ${msg.role === 'Admin' ? 'admin-content' : 'user-content'}`}>
                      <Avatar
                        src={`${process.env.PUBLIC_URL + (msg.role === 'Admin' ? "/logo23.png" : "/assets/images/avatars/avatar_6.jpg")}`}
                        className="message-avatar"
                        style={{ height: 28, width: 28 }}
                      />
                      <div className="message-bubble">
                        <div className="message-text">
                          {msg.text}
                          <span className="message-timestamp">
                            {new Date(msg.timestamp).toLocaleTimeString()}
                          </span>
                        </div>
                        {msg.image && <img src={msg.image} alt="attachment" className="message-image" />}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>

            <div className="chat-input-container">
              <Space style={{ width: '100%' }}>
                <Input
                  placeholder="Nhập vào tin nhắn"
                  rows={1}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onPressEnter={handleSendMessage}
                />
                <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage} style={{ background: "#57c8cc" }} />
                <FileInput onFileChange={onFileChange} disabled={!!image} />
              </Space>
            </div>
          </div>
        )
      }
    </div >
  );
};

export default Chat;
