import React, { useState, useEffect, useCallback } from 'react';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { bgBlur } from '~components/theme/css.tsx';
import Iconify from '~components/image/iconify/iconify.tsx';
import { setSearchKeyword } from "~redux/slices/common/commonSlice";
import { useDispatch } from 'react-redux';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'throttle-debounce';
import SearchAPI from '~services/commonService/search_api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  //@ts-ignore
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface HeaderProps {
  cartBounce: boolean;
}

const Searchbar: React.FC<HeaderProps> = (props) => {
  const commonDispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage('vn');
    }
  }, [i18n]);

  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchSuggestions = async (query: string) => {
    setLoading(true);
    try {
      const response = await SearchAPI.searchSuggestion(query);
      const data = response.suggestions;
      setSuggestions(data);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(debounce(350, fetchSuggestions), []);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setSuggestions([]);
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value.trim()) {
      debouncedFetchSuggestions(value.trim());
    } else {
      setSuggestions([]);
    }
    //@ts-ignore
    commonDispatch(setSearchKeyword({ searchKeyword: value }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClose();
      if (searchInput.trim()) {
        navigate(`/search?query=${encodeURIComponent(searchInput.trim())}`);
      } else {
        navigate(-1);
      }
    }
  };

  const handleClick = () => {
    handleClose();
    if (searchInput.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchInput.trim())}`);
    } else {
      navigate(-1);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSearchInput(suggestion);
    setSuggestions([]);
    handleClose();
    navigate(`/search?query=${encodeURIComponent(suggestion.trim())}`);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton
            onClick={handleOpen}
            sx={{
              borderRadius: 20,
            }}
          >
            {/*@ts-ignore */}
            <Iconify icon="eva:search-fill" />
            <Box>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  marginLeft: { xs: "1px", md: "30px" },
                  textAlign: "left",
                  width: { xs: "22vw", md: "40vw", lg: "50vw" }
                }}
              >
                {t('common.search')}
              </Typography>
            </Box>
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              onChange={handleSearchInput}
              onKeyDown={handleKeyDown}
              placeholder={t('common.searchLabel')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    //@ts-ignore
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button style={{ width: 200 }} variant="contained" onClick={handleClick}>
              {t('common.search')}
            </Button>
          </StyledSearchbar>
        </Slide>

        {loading && <CircularProgress sx={{ position: 'absolute', top: HEADER_MOBILE, right: 20 }} />}

        {suggestions.length > 0 && (
          <List sx={{
            position: { xs: 'fixed', md: 'absolute' },
            top: { xs: HEADER_MOBILE, md: HEADER_DESKTOP },
            left: { xs: 0 },
            width: { xs: '100%' },
            bgcolor: 'rgba(255, 255, 255, 0.99)',
            zIndex: 1001,
            color: '#333',
            fontSize: 'small',
            maxHeight: 300,
            overflowY: 'auto',
            marginLeft: { xs: 0, md: 'auto' }
          }}>
            {suggestions.slice(0, 10).map((suggestion, index) => (
              <ListItem button key={index} onClick={() => handleSuggestionClick(suggestion)}>
                <ListItemText
                  primary={
                    <span>
                      {suggestion.split(new RegExp(`(${searchInput})`, 'gi')).map((part, i) =>
                        part.toLowerCase() === searchInput.toLowerCase() ? (
                          <b key={i} style={{ backgroundColor: '#D1E9F6' }}>{part}</b>
                        ) : (
                          part
                        )
                      )}
                    </span>
                  }
                  sx={{ color: '#333', fontSize: 'small' }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </ClickAwayListener >
  );
};

export default Searchbar;
