import { getAccessToken } from "~utils/helper";
import api from "../commonService/apiConfig";


// Hàm tạo sản phẩm
const createProduct = async (data) => {
  try {
    const token = getAccessToken();
    const response = await api.post('/SanPham', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const createdProduct = response.data;
    return createdProduct;
  } catch (error) {
    throw new Error('Creating product failed');
  }
};



// Hàm thay đổi trạng thái sản phẩm
const changeStatus = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/SanPham/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Status product failed');
  }
};


// Hàm cập nhật sản phẩm
const updateProduct = async (id, data) => {
  try {
    const token = getAccessToken();
    const response = await api.patch(`/SanPham/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Change Status product failed');
  }
};



// Hàm sắp xếp sản phẩm theo giá
const sortProductsByPrice = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    // console.log(order);
    const sortOption = order === 'asc' ? 'gia,asc' : 'gia,desc';
    const response = await api.get(`/SanPham?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by Price');
  }
};


// Hàm sắp xếp sản phẩm theo id
const sortProductsById = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const sortOption = order === 'asc' ? 'id,asc' : 'id,desc';
    const response = await api.get(`/SanPham?sort=${sortOption}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by id');
  }
};


// Hàm sắp xếp sản phẩm theo trạng thái
const sortProductsByStatus = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?trangThai=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by Status');
  }
};


// Hàm sắp xếp sản phẩm theo id danh mục
const sortProductsByIdCategory = async (order, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?maLoaiSanPham=${order}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to sort products by id Category');
  }
};


// Hàm lấy sản phẩm theo id
const getProductById = async (id) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Không lấy được sản phẩm');
  }
};


// Hàm lấy tất cả sản phẩm
const getAllProduct = async (pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham`, {
      params: {
        sort: 'gia,asc',
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const ProData = response.data;
    return ProData;
  } catch (error) {
    throw new Error('failed');
  }
};




// Hàm xóa sản phẩm theo id
const deleteProduct = (id) => {
  const token = getAccessToken();
  return api.delete(`/SanPham/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );
};


// Hàm lấy sản phẩm đã thích theo id người dùng
const getLikedProductByUserId = async (maNguoiDung) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/SanPhamDaThich/${maNguoiDung}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching post:', error.response || error.message);
    throw new Error('Failed to fetch post');
  }
};

// Hàm lấy các sản phẩm tương tương tự
const getProposeProducts = async (maSanPham) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${maSanPham}/SanPhamDeXuat`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching post:', error.response || error.message);
    throw new Error('Failed to fetch post');
  }
};

// Hàm lấy thông tin sản phẩm đã xem của người dùng
const getViewedProductByUserId = async (maNguoiDung) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/SanPhamDaXem/${maNguoiDung}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
};


// Hàm cập nhật sản phẩm đã xem và đã thích
const updateDaXemVaDaThich = async (userId, sanPhamDaXem, sanPhamDaThich) => {
  try {
    const token = getAccessToken();
    const response = await api.post(`/SanPham/DaThichVaDaXem/${userId}`, { sanPhamDaXem, sanPhamDaThich }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
}




// Hàm lấy thông tin sản phẩm khác
const getOtherOption = async (id) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/${id}/otherOption`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching get:', error.response || error.message);
    throw new Error('Failed to fetch get');
  }
};

// Hàm sort sản phẩm
const getSortedProduct = async (sortBy) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham/sort?condition=${sortBy}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching sorted products:', error.response || error.message);
    throw new Error('Failed to fetch sorted products');
  }
};


// Hàm lấy sản phẩm đã lọc
const getFilteredProducts = async (
  pageNumber = 1,
  pageSize = 1000,
  filterState = {
    brand: "",
    category: "",
    priceMax: null,
    priceMin: null,
    rating: ""
  },
  sortBy = "top"
) => {
  try {
    const token = getAccessToken();
    const response = await api.post(`/SanPham/filter?condition=${sortBy}&pageSize=${pageSize}&pageNumber=${pageNumber}`, filterState, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching filtered products:', error.response || error.message);
    throw new Error('Failed to fetch filtered products');
  }
};

// Lấy sản phẩm theo tên
const searchProducts = async (searchTerm, pageNumber, pageSize) => {
  try {
    const token = getAccessToken();
    const response = await api.get(`/SanPham?sort=maSanPham,asc&search=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to search products');
  }
};


const ProductService = {
  // fetchProducts,
  getProductById,
  createProduct,
  changeStatus,
  updateProduct,
  searchProducts,
  // getAllProductByQrCode,
  sortProductsByPrice,
  sortProductsById,
  sortProductsByStatus,
  sortProductsByIdCategory,
  getAllProduct,
  deleteProduct,
  getLikedProductByUserId,
  getProposeProducts,
  updateDaXemVaDaThich,
  getViewedProductByUserId,
  getOtherOption,
  getSortedProduct,
  getFilteredProducts
}

export default ProductService;