import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationVI from './locales/vi/translation.json';
import translationJA from './locales/jp/translation.json';
import translationKR from './locales/kr/translation.json';
import translationDE from './locales/de/translation.json';
import translationCN from './locales/cn/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  vi: {
    translation: translationVI
  },
  jp: {
    translation: translationJA
  },
  kr:{
    translation: translationKR
  },
  de: {
    translation: translationDE
  },
  cn:{
    translation: translationCN
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'vi',
    lng: localStorage.getItem('language') || 'vi', 
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
